import { FC } from "react";
import styled from "styled-components";
import { Tab } from "./Tab/Tab";

const TabsContainer = styled.div`
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 2px solid #e1e1e1;
    display: flex;
    gap: 10px;
`

export interface TabInfo {
    name: string
}

interface TabsProps {
    tabs: TabInfo[]
    activeTab: number
    handleClick: (index: number) => void,
}

export const Tabs: FC<TabsProps> = ({ tabs, activeTab, handleClick }) => {
    return (
        <TabsContainer>
            {tabs.map((tab, index) => {
                return (
                    <Tab
                        key={index}
                        tab={tab}
                        isActive={activeTab === index}
                        handleClick={() => handleClick(index)}
                    />
                )
            })}
        </TabsContainer>
    )
}