import { IPaginationProps } from "../../types/base.interface";
import { IGroup } from "../../types/group.interface";
import { IUsers } from "../../types/user.interface";
import { api } from "../api";

export interface IGetUsersInGroupRequest extends IPaginationProps {
    groupId: string;
};

export const groupApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getActiveGroup: builder.query<IGroup[], void>({
            query: () => `/users/groups/active`,
            providesTags: (result, error, arg) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Group' as const, id })), 'Group']
                    : ['Group'],
        }),
        getArchivedGroup: builder.query<IGroup[], void>({
            query: () => `/users/groups/archived`,
            providesTags: (result, error, arg) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Group' as const, id })), 'Group']
                    : ['Group'],
        }),
        getUsersInGroup: builder.query<IUsers, IGetUsersInGroupRequest>({
            query: ({groupId, pageNumber, pageSize}) => `/users/${groupId}/${pageNumber}/${pageSize}`,
            providesTags: (result, error, arg) =>
                result
                    ? [...result.users.map(({ id }) => ({ type: 'UsersInGroup' as const, id })), 'UsersInGroup']
                    : ['UsersInGroup'],
        }),
    }),
})



