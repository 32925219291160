import styled from "styled-components";
import { FixedHeader } from "../common/header/FixedHeader";
const background: string = require("../../assets/images/student-background.svg").default;

const LogoContainer = styled.div`
    width: 100px;
    height: 44px;

    img {
        width: 100%;
        height: 100%;
    }
`

const StudentPageFixedHeader = styled(FixedHeader)`
    gap: 20px;
    padding: 15px 15.625%;
    /* padding: 15px 18.75%; */
    justify-content: space-between;
`

const StudentPageBody = styled.div`
    min-height: 100vh;
    margin-top: 72px;
    flex: 1;
    display: flex;
    flex-direction: column;
`

const StudentPageMain = styled.div`
    flex: 1;

    background-color: #FAFAFA;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const StudentPageBackground = styled.div`
    width: 9.375%;

    background-image: url(${background});
    background-repeat: repeat-y;
    background-size: 100%;
`

const StudentPageContainer = styled.div`
    position: relative;

    flex: 1;
    margin: 30px 6.25%;
`

const StudentPageContent = styled.div`
    /* padding: 0 4.545454545%; */
    flex: 1;
    background-color: #FAFAFA;
`

export {
    LogoContainer, StudentPageBackground, StudentPageBody, StudentPageContainer,
    StudentPageContent, StudentPageFixedHeader, StudentPageMain
};

