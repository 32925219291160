import React from 'react';
import { IColumnProps } from './types';
import HeaderCell from './HeaderCell';

function Column<T>(props: IColumnProps<T>) {
    return <>{props.children}</>;
}

export function convertColumn<T>(item: React.ReactElement<IColumnProps<T>>) {
    if (item.type === Column) {
        let headerCell: React.ReactElement = <></>;
        let cell: React.ReactElement = <></>;

        if (item.props.children) {
            if (item.props.children instanceof Array) {
                for (const elem of item.props.children) {
                    elem.type === HeaderCell ? (headerCell = elem) : (cell = elem);
                }
            } else {
                const elem = item.props.children;
                elem.type === HeaderCell ? (headerCell = elem) : (cell = elem);
            }
        }

        let width: string | undefined;

        if (item.props.width) {
            width = item.props.width;
        }

        return {
            header: headerCell,
            cell,
            width,
        };
    }
    return null;
}

export default Column;
