export const ROUTES = {
    rootPath: '*',
    auth: {
        path: '/auth/*',
        login: {
            path: 'login',
            fullPath: '/auth/login',
        },
        register: {
            path: 'register',
            fullPath: '/auth/register',
        }
    },
    student: {
        path: '/student/*',
        courses: {
            path: 'courses',
            fullPath: '/student/courses',
        },
        currentCourse: {
            path: 'courses/:courseId',
            fullPath: '/student/courses/:courseId',
        },
        currentTask: {
            path: 'tasks/:taskId',
            fullPath: '/student/tasks/:taskId',
        }
    },
    teacher: {
        path: '/teacher/*',
        courses: {
            path: 'courses',
            fullPath: '/teacher/courses',
        },
        currentCourse: {
            path: 'courses/:courseId',
            fullPath: '/teacher/courses/:courseId',
        },
        students: {
            path: 'students',
            fullPath: '/teacher/students',
        },
        currentStudent: {
            path: 'students/:studentId',
            fullPath: '/teacher/students/:studentId',
        },
        tasks: {
            path: 'tasks',
            fullPath: '/teacher/tasks',
        },
        currentTask: {
            path: 'tasks/:taskId',
            fullPath: '/teacher/tasks/:taskId',
        },
        createTask: {
            path: 'tasks/create',
            fullPath: '/teacher/tasks/create',
        },
        editTask: {
            path: 'tasks/edit/:taskId',
            fullPath: '/teacher/tasks/edit/:taskId',
        }
    }
}
