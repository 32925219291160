import { FC, useEffect, useState } from 'react';
import { eventApi } from '../../../core/api/rtk-query/event.api';
import { groupApi } from '../../../core/api/rtk-query/group.api';
import { MainHeadingText, SecondaryHeadingTextForEmptyStates } from '../../../styles/Text';
import { getGroupMaxPoints, getGroupUserPoints } from '../../../utils/cards.utils';
import { getDayjs, getDueDate } from '../../../utils/dayjs.utils';
import { CoursesGrid } from '../../common/main/courses/CoursesGrid';
import { CoursesCardProps } from '../../common/main/courses/card/CourseCard';
import { EventCardProps } from '../../common/main/events/card/EventCard';
import { TabInfo, Tabs } from '../../common/main/tabs/Tabs';
import { StudentCoursesWrapper } from './Styles';
import { IEventCardProps } from '../../common/main/events/card/types';

const calendarImg = require('../../../../src/assets/icons/main/calendar.svg').default;

const cursesTabs: TabInfo[] = [
    {
        name: 'Активные курсы'
    },
    {
        name: 'Пройденные курсы'
    }
];

type StudentCoursesContainerProps = { isLoading: boolean; courses: CoursesCardProps[] | undefined; activeTab: number };

const StudentCoursesContainer = ({ isLoading, courses, activeTab }: StudentCoursesContainerProps) => {
    if (isLoading) {
        return <SecondaryHeadingTextForEmptyStates>ЗАГРУЗКА</SecondaryHeadingTextForEmptyStates>;
    }

    if (!courses?.length) {
        return (
            <SecondaryHeadingTextForEmptyStates>
                {activeTab == 0 ? 'Вы пока не записаны на курсы' : 'У вас пока нет завершенных курсов'}
            </SecondaryHeadingTextForEmptyStates>
        );
    }

    return <StudentCoursesWrapper>{courses && <CoursesGrid repeat={3} courses={courses} />}</StudentCoursesWrapper>;
};

export const StudentCoursesPage: FC = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [courses, setCourses] = useState<CoursesCardProps[] | undefined>(undefined);

    //TODO: подумать как сделать индивидуальные таски (мб пересмотреть хранение)
    const [individualTasks, setIndividualTasks] = useState<IEventCardProps[] | undefined>(undefined);

    //TODO: подумать можно ли не грузить сразу активные и архивные группы
    const { data: groupActive, isLoading: groupActiveIsLoading } = groupApi.useGetActiveGroupQuery();
    const { data: groupArchived, isLoading: groupArchivedIsLoading } = groupApi.useGetArchivedGroupQuery();
    const { data: events, isLoading: eventsIsLoading } = eventApi.useGetEventsQuery();
    const { data: userEvents, isLoading: userEventsIsLoading } = eventApi.useGetUserEventsQuery();
    const isLoading = groupActiveIsLoading || groupArchivedIsLoading || eventsIsLoading || userEventsIsLoading;

    useEffect(() => {
        if (!isLoading) {
            const groupData = activeTab === 0 ? groupActive : groupArchived;
            setCourses(
                groupData?.map(group => {
                    const start = getDayjs(group.startTime);
                    const end = getDayjs(group.endTime);
                    return {
                        id: group.id,
                        name: group.name,
                        info: [
                            {
                                imgSrc: calendarImg,
                                condition: getDueDate(start, end)
                            }
                        ],
                        point: {
                            name: 'Прогресс выполнения заданий',
                            max: getGroupMaxPoints(events, group.id),
                            now: getGroupUserPoints(userEvents, events, group.id)
                        }
                    };
                })
            );
        }
    }, [isLoading, activeTab]);

    return (
        <>
            <MainHeadingText style={{ marginBottom: 30 }}>Мои курсы</MainHeadingText>
            <Tabs tabs={cursesTabs} activeTab={activeTab} handleClick={index => setActiveTab(index)} />
            <StudentCoursesContainer isLoading={isLoading} courses={courses} activeTab={activeTab} />
        </>
    );
};
