import { MouseEventHandler } from 'react';
import styled from 'styled-components';

const closeIcon: string = require('../../../../assets/icons/close.svg').default;

const ButtonStyled = styled.button`
    background-color: transparent;
    border: none;
    padding: 8px;
    cursor: pointer;
`;

const ImgStyled = styled.img`
    width: 24px;
    height: 24px;
`;

export const CloseButton = ({ onClick }: { onClick: MouseEventHandler}) => {
    return (
        <ButtonStyled onClick={onClick}>
            <ImgStyled src={closeIcon} alt="Close" />
        </ButtonStyled>
    );
};
