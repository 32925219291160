import { forwardRef } from "react";
import { ISelect } from "../../types/select.interface";
import styled from "styled-components";

export const SelectStyled = styled.select`
    box-sizing: border-box;
    width: 100%;

    border: 1px solid #e1e1e1;
    border-radius: 6px;
    padding: 8px 12px;

    color: #787676;

    &:focus {
        outline: none;
        border-color: #0b68fe;
    }

    &:focus::placeholder {
        color: transparent;
    }

    //TODO: use BodyBigRegularText
    margin: 0;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
`;

export interface SelectOption  {
    name: string;
    label: string;
}

export type FormSelectProps = ISelect & { options: SelectOption[] };

export const TaskFormSelect = forwardRef<HTMLInputElement, FormSelectProps>(
    ({ options, onChange, name, ...rest }, ref) => {
    return (
        <SelectStyled name={name} onChange={onChange} {...rest}>
            {options.map(o => (
                <option key={o.name} value={o.name}>{o.label}</option>
            ))}
        </SelectStyled>
    );
});