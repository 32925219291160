// import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useRoutes } from './routes/routes';

// import dayjs from 'dayjs';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// import duration from 'dayjs/plugin/duration';
// dayjs.extend(customParseFormat)
// dayjs.extend(duration)

export const App = () => {
    const routes = useRoutes();

    return (
        <BrowserRouter>{routes}</BrowserRouter>
    )
};
