import React, { useState, useEffect } from "react";
import { IProblem, IUserAttempt } from "../../../../../../../core/types/problem.interface";
import Table from "../../../../../../UI/Table/Table";
import { eventApi } from "../../../../../../../core/api/rtk-query/event.api";
import { useGetCurrentEvent } from "../../../../../../../hooks/api/useGetCurrentEvent";
import styled from "styled-components";

const TasksContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-around;
`

export interface StudentResultsTableProps {
    activeTab: number,
    filterText: string,
    id: string, // taskId
    problems: IProblem[],
    currentPage: number,
    itemsPerPage: number
}

export interface IUserAttemptWithId extends IUserAttempt {
    id: string;
};

export const StudentResultsTable = (props: StudentResultsTableProps) => {
    const [page, setPage] = useState(1);
    const { event } = useGetCurrentEvent();

    if (!event) {
        return <></>;
    }

    const taskIndexes = [...Array(event.problems.length)].map((_, i) => { return i + 1 })

    const { data: userAttempts } = eventApi.useGetUserAttemptsQuery({
        eventId: event.id,
        reviewed: props.activeTab === 0,
        pageNumber: page,
        pageSize: 10
    })

    return (
        <Table
            data={userAttempts?.userAttempts.map((attmept) => {
                return {
                    ...attmept,
                    id: attmept.userId,
                }
            })}
            pageSize={page}
        >
            <Table.Column width="250px">
                <Table.HeaderCell>№ ИСУ</Table.HeaderCell>
                <Table.Cell dataKey='isu'>
                    {(rowData: IUserAttemptWithId) => (
                        <div>{rowData.isuNumber}</div>
                    )}
                </Table.Cell>
            </Table.Column>
            <Table.Column width="500px">
                <Table.HeaderCell>ФИО</Table.HeaderCell>
                <Table.Cell dataKey='name'>
                    {(rowData: IUserAttemptWithId) => (
                        <div>{rowData.name}</div>
                    )}
                </Table.Cell>
            </Table.Column>
            <Table.Column width="150px">
                <Table.HeaderCell>Общий балл</Table.HeaderCell>
                <Table.Cell>
                    {(rowData: IUserAttemptWithId) => {
                        let maxScore = 0;
                        let userScore = 0;
                        rowData.problemScores.forEach((score) => {
                            maxScore += score.maximumScore;
                            userScore += score.userScore;
                        })
                        return <div>{`${userScore}/${maxScore}`}</div>
                    }}
                </Table.Cell>
            </Table.Column>
            <Table.Column width="60px">
                <Table.HeaderCell>Задания</Table.HeaderCell>
            </Table.Column>
            <Table.Column width="175px">
                <Table.HeaderCell>
                    <TasksContainer>
                        {taskIndexes.map((number, index) => {
                            return <div key={index}>{number}</div>
                        })}
                    </TasksContainer>
                </Table.HeaderCell>
                <Table.Cell>
                    {(rowData: IUserAttemptWithId) => (
                        <TasksContainer>
                            {taskIndexes.map((number, index) => {
                                return <div key={index}>{`${rowData.problemScores[index].userScore}/${rowData.problemScores[index].maximumScore}`}</div>
                            })}
                        </TasksContainer>
                    )}
                </Table.Cell>
            </Table.Column>
        </Table >
    )
}