import styled from 'styled-components';

export const FixedHeader = styled.header`
     position: fixed;
    top: 0;
    z-index: 150;

    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.07);
    z-index: 10;
`;
