import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { Role } from "../../types/user.interface";
import { login, logout, register } from "./auth.actions";
import { IAuthInitialState } from "./auth.interface";

const initialState: IAuthInitialState = {
    isLoading: false,
    token: undefined,
    role: undefined,
}

interface Token {
    role: keyof typeof Role
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(login.pending, state => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.token = payload.token
                state.role = Role[jwtDecode<Token>(payload.token).role]
            })
            .addCase(login.rejected, state => {
                state.isLoading = false
                state.token = undefined
                state.role = undefined
            })
            .addCase(register.pending, state => {
                state.isLoading = true
            })
            .addCase(register.fulfilled, state => {
                state.isLoading = false
            })
            .addCase(register.rejected, state => {
                state.isLoading = false
            })
            .addCase(logout.fulfilled, state => {
                state.token = undefined
                state.role = undefined
            })
    }
})