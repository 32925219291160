import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../../routes/routes.config';
import { BodyBaseRegularText, BodyBigRegularText } from '../../../../../../styles/Text';
import { TasksSidebarItem } from '../Styles';

interface TimerProps {
    timeInSeconds: number;
}

export const Timer: FC<TimerProps> = ({ timeInSeconds }) => {
    const [timeLeft, setTimeLeft] = useState(timeInSeconds);

    const navigate = useNavigate()

    const onTimerFinish = () => {
        navigate(ROUTES.rootPath)
    }

    useEffect(() => {
        if (timeLeft > 0) {
            setTimeout(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        } else {
            onTimerFinish && onTimerFinish();
        }
    }, [timeLeft, onTimerFinish]);

    const formatTime = (seconds: number): string => {
        const mins = Math.floor(seconds / 60);
        const hours = Math.floor(mins / 60);
        const secs = seconds % 60;
        return `${hours}ч ${mins - hours * 60}мин ${secs}с`;
    };

    return (
        <TasksSidebarItem>
            <BodyBaseRegularText style={{ color: '#787676' }}>
                До завершения
            </BodyBaseRegularText>
            <BodyBigRegularText style={{ color: '#0b68fe' }}>
                {formatTime(timeLeft)}
            </BodyBigRegularText>
        </TasksSidebarItem>
    );
};
