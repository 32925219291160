import React, {FC} from "react";
import {TaskStatsContainer} from "../../Styles";
import {TaskDetails, TaskDetailsProps} from "./TaskDetails";

export const TaskStats: FC<TaskDetailsProps> = (taskDetailProps) => {
    return (
        <TaskStatsContainer>
            <TaskDetails {...taskDetailProps}/>
        </TaskStatsContainer>
    )
}