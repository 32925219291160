import { IBase } from "./base.interface";
import { IProblem, IProblemAttempts } from "./problem.interface";

export enum EventType {
    Homework = "Homework",
    Contest = "Contest",
    Milestone = "Milestone",
    Exam = "Exam",
}

export interface IEvent extends IBase {
    name: string
    groupId: string
    startTime: string
    endTime: string
    duration: string // d.hh:mm:ss
    type: keyof typeof EventType
    minimumPointsToPass: number
    description: string
    problems: IProblem[]
}

export interface ICreateEvent {
    id?: string,
    name: string
    groupId: any // i don't know what is
    startTime: string
    endTime: string
    duration: any    // d.hh:mm:ss
    type: keyof typeof EventType
    minimumPointsToPass: number
    description: string
    problemCreationRequests: IProblem[]
}

export interface IUserEvent {
    eventId: string
    userId: string
    startTime: string
    reviewed: boolean
    isManuallyFinished: boolean
    problemAttempts: IProblemAttempts[]
}