import React from 'react';
import { TaskPageContainer } from "./Styles";
import { TaskInfo } from "./ui/task-info/TaskInfo";
import { TaskResults } from "./ui/task-results/TaskResults";
import { TaskStats } from "./ui/task-stats/TaskStats";
import { useGetCurrentEvent } from '../../../../hooks/api/useGetCurrentEvent';


export const TeacherTaskPage = () => {
    const { event } = useGetCurrentEvent();

    return (
        <>
            <TaskPageContainer>
                {event &&
                    <>
                        <TaskInfo {...event} />
                        <TaskStats {...event} />
                        <TaskResults {...event} />
                    </>
                }
            </TaskPageContainer>
        </>
    );
};
