import styled from "styled-components";
import { ButtonBigText } from "../../../../styles/Text";

export const WhiteButton = styled(ButtonBigText)`
    height: 36px;

    padding: 8px;
    border-radius: 4px;
    border: 1px solid #0B68FE;

    background-color: #FFFFFF;
    color: #0B68FE;

    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: #FAFAFA;
    }

    &:active {
        background-color: #F8F8F8;
    }
`