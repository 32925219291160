import { FC } from 'react';
import { Latex } from '../../../../../../common/main/latex/Latex';

interface TaskContentProps {
    content: string;
}

export const TaskContent: FC<TaskContentProps> = ({ content }) => {
    return (
        <Latex content={content} />
    );
};
