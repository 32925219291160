import { configureStore } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { api } from "../api/api";
import { rtkQueryErrorLogger } from "./middlewares/error.middleware";
import { rootReducer } from "./root.reducer";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ['auth'],
    blacklist: [api.reducerPath],
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }).concat(rtkQueryErrorLogger).concat(api.middleware)
});

export const persistor = persistStore(store);

export type TypeRootState = ReturnType<typeof rootReducer>;
