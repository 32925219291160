import { FC, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { Role } from "../../../../../core/types/user.interface";
import { useNavigateTo } from "../../../../../hooks/useNavigateTo";
import { BodyBigBoldText, BodyBigRegularText } from "../../../../../styles/Text";
import { BlueButton } from "../../button/BlueButton";
import { WhiteButton } from "../../button/WhiteButton";
import { CardInfo } from "../../card/CardInfo";
import { CardInfoItemProps } from "../../card/info/CardInfoItem";
import { ProgressBar, ProgressBarProps } from "../../progressbar/ProgressBar";
import { IButtonInfo } from "./types";
import { eventApi } from "../../../../../core/api/rtk-query/event.api";
import { useAuth } from "../../../../../hooks/useAuth";

const EventCardContainer = styled.div`
    height: 335px;

    background-color: #FFFFFF;
    box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
`

const EventCardContent = styled.div`
    box-sizing: border-box;
    padding: 25px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const EventCardDiv = styled.div`
    display: flex;
    flex-direction: column;
`

const EventDescription = styled(BodyBigRegularText)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ButtonArea = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
`

export interface ButtonInfo {
    blueButtonText?: string
    whiteButtonText?: string
}

export interface EventCardProps {
    id: string
    name: string
    info: CardInfoItemProps[]
    description: string
    point?: ProgressBarProps
    buttons: IButtonInfo
}

export const EventCard: FC<EventCardProps> = ({ id, name, info, description, point, buttons }) => {
    const [isStart, setIsStart] = useState<boolean>(false);
    const { role } = useAuth()

    const [startEvent] = eventApi.useStartEventMutation();
    const { data: userEvents, isLoading, isFetching } = eventApi.useGetUserEventsQuery();

    const navigateTo = useNavigateTo()

    const handleClick = () => {
        if (!(userEvents?.map(userEvent => userEvent.eventId).includes(id))) {
            startEvent({ eventId: id, language: 'ru' }).unwrap().then(() => {
                toast.success('Вы начали задание');
            })
            return setIsStart(true);
        }
        navigateTo(`tasks/${id}`);
    }

    useEffect(() => {
        if (isStart && !isFetching) {
            navigateTo(`tasks/${id}`);
        }
    }, [isFetching])

    const handleEditClick = () => {
        navigateTo(`tasks/edit/${id}`)
    }

    const handleShowClick = () => {
        navigateTo(`tasks/${id}`)
    }

    return (
        <EventCardContainer >
            <EventCardContent>
                <EventCardDiv style={{ gap: '10px' }}>
                    <BodyBigBoldText>{name}</BodyBigBoldText>
                    <EventDescription>{description}</EventDescription>
                    {info && <CardInfo conditions={info} />}
                </EventCardDiv>
                <EventCardDiv style={{ gap: '15px' }}>
                    {point && (
                        <ProgressBar {...point} />
                    )}
                    <ButtonArea style={{ gap: '10px' }}>
                        {buttons.whiteButtonText && (
                            <WhiteButton
                                style={{ width: '250px', alignSelf: 'center' }}
                                onClick={() => { role === Role.Student ? handleClick() : handleEditClick() }}
                            >
                                {buttons.whiteButtonText}
                            </WhiteButton>
                        )}
                        {buttons.blueButtonText && (
                            <BlueButton
                                style={{ width: '250px', alignSelf: 'center' }}
                                onClick={() => { role === Role.Student ? handleClick() : handleShowClick() }}
                                disabled={isLoading}
                            >
                                {buttons.blueButtonText}
                            </BlueButton>
                        )}
                        {!buttons.whiteButtonText && !buttons.blueButtonText &&
                            <div style={{height: "36px", width: "250px"}}>
                                <Skeleton />
                            </div>
                        }
                    </ButtonArea>
                </EventCardDiv>
            </EventCardContent>
        </EventCardContainer>
    )
}