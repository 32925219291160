import styled from "styled-components";
const background: string = require("../../assets/images/auth-background.svg").default;

const AuthPageBody = styled.div`
    display: flex;
    flex-direction: row;

    min-height: 100vh;
`;

const AuthPageBackground = styled.div`
    flex: 55.885%;

    background-image: url(${background});
    background-repeat: repeat-y;
    background-size: 100%;
`;

const AuthPageContainer = styled.div`
    position: relative;
    flex: 44.115%;
    height: 100%;
`

const AuthPageMain = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #f8faff;
`;

const Logo = styled.img`
    width: 180px;
    height: 79px;
`;

const AuthPageContent = styled.div`
    width: 330px;
    min-height: 300px;

    margin: 24.5px;
    padding: 50px 40px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
`;

const AuthForm = styled.form`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
`;

const AuthFormField = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
`;

const AuthFormContainer = styled.div`
    width: 100%;
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`

export {
    AuthForm, AuthFormContainer, AuthFormField,
    AuthPageBackground, AuthPageBody, AuthPageContainer, AuthPageContent,
    AuthPageMain, Logo
};

