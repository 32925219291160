import React, {FC} from "react";
import {GeneralTaskInfoContainer, TaskInfoContainer, TaskNameText, TaskTypeText} from "../../Styles";
import {EditTaskLink} from "./links/EditTaskLink";
import {EventType} from "../../../../../../core/types/event.interface";

const getTaskTypeName = (eventType: EventType) => {
    switch (eventType) {
        case EventType.Homework:
            return 'Домашняя работа';
        case EventType.Contest:
            return 'Контрольная работа';
        case EventType.Milestone:
            return 'Рубежная работа';
        case EventType.Exam:
            return 'Экзамен';
        default:
            return '';
    }
}

export interface TaskInfoProps {
    name: string,
    type: keyof typeof EventType
}

export const TaskInfo: FC<TaskInfoProps> = ({name, type}) => {
    return (
        <TaskInfoContainer>
            <GeneralTaskInfoContainer>
                <TaskNameText>{name}</TaskNameText>
                <TaskTypeText>{getTaskTypeName(EventType[type])}</TaskTypeText>
            </GeneralTaskInfoContainer>
            <EditTaskLink/>
        </TaskInfoContainer>
    )
}