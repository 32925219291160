import { FC } from 'react';
import { Outlet } from "react-router-dom";
import { CustomToaster } from '../common/toast/CustomToaster';
import { TeacherPageBody, TeacherPageContent } from "./Styles";
import { TeacherHeader } from "./ui/header/TeacherHeader";
import { TeacherSideBar } from "./ui/sidebar/TeacherSideBar";


export const TeacherPage: FC = () => {
    return (
        <TeacherPageBody>
            <TeacherHeader />
            <TeacherSideBar />
            <TeacherPageContent>
                <CustomToaster top='92px' />
                <Outlet />
            </TeacherPageContent>
        </TeacherPageBody>
    );
};
