import { forwardRef, useState } from "react";
import styled from "styled-components";
import { IInput } from "../../types/input.interface";
import { Input } from "./Input";

const eyeOpen: string =
    require("../../../../assets/icons/auth/eye-open.svg").default;
const eyeClose: string =
    require("../../../../assets/icons/auth/eye-close.svg").default;

interface PasswordInputStyledProps {
    borderColor: string;
}

const PasswordInputStyled = styled.div<PasswordInputStyledProps>`
    width: 100%;
    display: flex;
    justify-content: space-between;

    border: 1px solid;
    border-color: ${(p) => p.borderColor};
    border-radius: 6px;

    input {
        padding-right: 0;
    }
`;

const EyeImg = styled.img`
    width: 24px;
    height: 24px;
    padding: 8px 12px;
    cursor: pointer;
`;

export const PasswordInput = forwardRef<HTMLInputElement, IInput>(
    ({ ...rest }, ref) => {
        const [type, setType] = useState("password");
        const [isFocus, setIsFocus] = useState(false);

        const changePasswordTypeHandler = () => {
            if (type === "password") {
                setType("text");
            } else {
                setType("password");
            }
        };

        return (
            <PasswordInputStyled
                tabIndex={0}
                ref={ref}
                borderColor={isFocus ? "#0B68FE" : "#E1E1E1"}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
            >
                <Input
                    type={type}
                    style={{ border: "0" }}
                    {...rest}
                ></Input>
                <EyeImg
                    src={type === "password" ? eyeClose : eyeOpen}
                    onClick={() => changePasswordTypeHandler()}
                    alt="change password type"
                />
            </PasswordInputStyled>
        );
    }
)
