import { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { BodyBigBoldText, BodySmallRegularText } from '../../../../../styles/Text';
import { TaskFormInput } from '../../../../common/main/form/TaskFormInput';
import { BlueButton } from '../../../../common/main/button/BlueButton';
import { daysFromToInitialValue } from '../../../../../utils/daysFromToInitialValue';
import { isDateValid, isTimeValid } from '../../../../../utils/dayjs.utils';

const DateSelectionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const DateTimeControls = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
`;

const DateContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
`;

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
`;

const IncreaseDateTimeButton = styled(BlueButton)`
    height: 32px;
    padding: 0px;
`;

const DateInput = styled(TaskFormInput)`
    width: 110px;
    height: 40px;
`;

const TimeInput = styled(TaskFormInput)`
    width: 90px;
    height: 40px;
`;

interface ChooseDateComponentProps {
    onDatesChange: (dates: { fromDate: string; fromTime: string; toDate: string; toTime: string }) => void;
}

export const ChooseDateComponent: React.FC<ChooseDateComponentProps> = ({ onDatesChange }) => {
    const [selectedDates, setSelectedDates] = useState({
        fromDate: daysFromToInitialValue.fromDateInitialValue,
        fromTime: daysFromToInitialValue.timeInitialValue,
        toDate: daysFromToInitialValue.toDateInitialValue,
        toTime: daysFromToInitialValue.timeInitialValue,
    });

    const [dateTimeError, setDateTimeError] = useState<string | null>(null);

    const validateDate = (value: string) => {
        if (!isDateValid(value)) {
            setDateTimeError('Некорректная дата, проверьте правильность заполнения');
        }
    };
    const validateTime = (value: string) => {
        if (!isTimeValid(value)) {
            setDateTimeError('Некорректное время, проверьте правильность заполнения');
        }
    };
    const validateDateTime = (name: string, value: string) => {
        if (name.includes('Date')) {
            validateDate(value);
        } else {
            validateTime(value);
        }
    };
    const updateState = (name: string, value: string) => {
        setDateTimeError(null);
        setSelectedDates(storedDates => {
            const updatedState = {
                ...storedDates,
                ...{ [name]: value },
            };
            validateDateTime(name, value);
            if (!dateTimeError) {
                onDatesChange(updatedState);
            }
            return updatedState;
        });
    };
    const handleManualDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateState(event.target.name, event.target.value);
    };
    const increaseDateTime = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { name } = event.currentTarget;
        switch (name) {
            case 'increaseByHour':
                updateState('toTime', dayjs(selectedDates.toTime, 'HH:mm').add(1, 'hour').format('HH:mm'));
                break;
            case 'increaseByDay':
                updateState('toDate', dayjs(selectedDates.toDate, 'DD.MM.YYYY').add(1, 'day').format('DD.MM.YYYY'));
                break;
            case 'increaseByWeek':
                updateState('toDate', dayjs(selectedDates.toDate, 'DD.MM.YYYY').add(1, 'week').format('DD.MM.YYYY'));
                break;
        }
    };

    return (
        <DateSelectionWrapper>
            <BodyBigBoldText>
                Даты выполнения <span style={{ color: '#F3163E' }}>*</span>
            </BodyBigBoldText>
            <DateTimeControls>
                <DateContainer>
                    <DateInput name="fromDate" value={selectedDates.fromDate} onChange={handleManualDateTimeChange} />
                    <TimeInput name="fromTime" value={selectedDates.fromTime} onChange={handleManualDateTimeChange} />
                </DateContainer>
                <span>—</span>
                <DateContainer>
                    <DateInput name="toDate" value={selectedDates.toDate} onChange={handleManualDateTimeChange} />
                    <TimeInput name="toTime" value={selectedDates.toTime} onChange={handleManualDateTimeChange} />
                </DateContainer>
                <ButtonsContainer>
                    <IncreaseDateTimeButton
                        name="increaseByHour"
                        style={{ width: '67px' }}
                        type="button"
                        onClick={increaseDateTime}
                    >
                        + Час
                    </IncreaseDateTimeButton>
                    <IncreaseDateTimeButton
                        name="increaseByDay"
                        style={{ width: '76px' }}
                        type="button"
                        onClick={increaseDateTime}
                    >
                        + День
                    </IncreaseDateTimeButton>
                    <IncreaseDateTimeButton
                        name="increaseByWeek"
                        style={{ width: '89px' }}
                        type="button"
                        onClick={increaseDateTime}
                    >
                        + Неделя
                    </IncreaseDateTimeButton>
                </ButtonsContainer>
            </DateTimeControls>
            {dateTimeError && (
                <BodySmallRegularText style={{ color: 'rgb(243, 22, 62)' }}>{dateTimeError}</BodySmallRegularText>
            )}
        </DateSelectionWrapper>
    );
};
