import { isRejectedWithValue } from "@reduxjs/toolkit";
import { Middleware, MiddlewareAPI } from "redux";
import { toastError } from "../../../utils/api.utils";

export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => next => action => {
        if (isRejectedWithValue(action)) {
            toastError(action.error.data.message)
        }

        return next(action)
    }