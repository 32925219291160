import React, { useState } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BodySmallText, BodyBigBoldText, BodyBigRegularText } from '../../../../../styles/Text';
import { ChooseDateComponent } from './ChooseDate';
import { ChooseDurationComponent } from './ChooseDuration';
import { ChooseMinPointsComponent } from './ChooseMinPoints';
import { convertMinutesToDurationFormat } from '../../../../../utils/convertMinutesToDurationFormat';
import { EventType } from '../../../../../core/types/event.interface';
import { TaskFormInput } from '../../../../common/main/form/TaskFormInput';
import { BlueButton } from '../../../../common/main/button/BlueButton';
import { SelectStyled, TaskFormSelect } from '../../../../common/main/form/TaskFormSelect';
import { TaskFormTextAreaInput } from '../../../../common/main/form/TaskFormTextArea';
import { IProblem } from '../../../../../core/types/problem.interface';
import { eventApi } from '../../../../../core/api/rtk-query/event.api';
import { groupApi } from '../../../../../core/api/rtk-query/group.api';
import { daysFromToInitialValue } from '../../../../../utils/daysFromToInitialValue';

const CreateTaskFormStyled = styled.form`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    gap: 50px;
    flex: 1 0 0;
    margin-right: 57px;
`;

const CountSymbols = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const ButtonStyled = styled.div`
    display: flex;
    width: 100%;
    height: 40px;
    padding: 8px 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
`;

const CreateTaskFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
`;

const CreateTaskFormField = styled.div`
    display: flex;
    width: 715px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

const RadioButtonStyled = styled.div`
    display: flex;
    width: 140px;
    align-items: center;
    gap: 8px;
`;

const AssigneeContainerStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

export interface ICreateTaskFields {
    type: EventType;
    name: string;
    description: string;
    course?: string;
    student?: string;
}

const universityEventTypesDecriptions: { name: EventType; label: string }[] = [
    {
        name: EventType.Homework,
        label: 'Домашнее задание',
    },
    {
        name: EventType.Contest,
        label: 'Контрольная работа',
    },
    {
        name: EventType.Milestone,
        label: 'Рубежная работа',
    },
    {
        name: EventType.Exam,
        label: 'Экзамен',
    },
];

interface CreateNewTaskProps {
    selectedTasks: IProblem[];
}

export const CreateNewTask: React.FC<CreateNewTaskProps> = ({ selectedTasks }) => {
    const {
        register,
        watch,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<ICreateTaskFields>({
        mode: 'onChange',
        defaultValues: {
            name: '',
            type: EventType.Homework,
            description: '',
        },
    });
    const [createEvent] = eventApi.useCreateEventMutation();
    const [grantEventAccessForGroup] = eventApi.useGrantEventAccessForGroupMutation();

    const [eventDates, setEventDates] = useState({
        startTime: daysFromToInitialValue.startTimeInitialValueISO,
        endTime: daysFromToInitialValue.endTimeInitialValueISO,
    });

    const [eventDuration, setEventDuration] = useState<string>('00.00:45:00');
    const [minimumPoints, setMinimumPoints] = useState<number>(5);

    const handleDatesChange = (dates: { fromDate: string; fromTime: string; toDate: string; toTime: string }) => {
        const startTime = `${dates.fromDate}T${dates.fromTime}`;
        const endTime = `${dates.toDate}T${dates.toTime}`;

        const startTimeISO = dayjs(startTime, 'DD.MM.YYYYTHH:mm').format('YYYY-MM-DDTHH:mm:ss[Z]');
        const endTimeISO = dayjs(endTime, 'DD.MM.YYYYTHH:mm').format('YYYY-MM-DDTHH:mm:ss[Z]');

        setEventDates({ startTime: startTimeISO, endTime: endTimeISO });
    };

    const handleDurationChange = (durationNumber: number) => {
        const durationFormatted = convertMinutesToDurationFormat(durationNumber);

        setEventDuration(durationFormatted);
    };

    const handleMinPointsChange = (changedPoints: number) => {
        setMinimumPoints(changedPoints);
    };

    const onSubmit: SubmitHandler<ICreateTaskFields> = async data => {
        if (!problemRequestsArray.length) {
            toast.error('Не выбрано ни одной задачи!');
            return;
        }
        if (!selectedGroup) {
            toast.error('Не выбран курс! ');
            return;
        }
        if (isNaN(minimumPoints) || minimumPoints <= 0) {
            toast.error('Ошибка в минимальном количестве баллов!');
            return;
        }
        try {
            const createdEvent = await createEvent({
                name: data.name,
                description: data.description,
                type: data.type,
                groupId: selectedGroup,
                startTime: eventDates.startTime,
                endTime: eventDates.endTime,
                duration: eventDuration,
                minimumPointsToPass: minimumPoints,
                problemCreationRequests: problemRequestsArray,
            }).unwrap();
    
            if (createdEvent.id) {
                toast.success('Задание создано');
                await grantEventAccessForGroup({
                    eventId: createdEvent.id,
                    groupId: selectedGroup,
                }).unwrap();
            } else {
                toast.error('У созданного ивента нет id');
            }
        } catch (error) {
            toast.error('Произошла ошибка при создании задания');
        }
    };
    const [selectedAssignee, setSelectedAssignee] = useState<'group' | 'student' | null>(null);
    const [selectedGroup, setSelectedGroup] = useState<string>('');
    const { data: groupActiveData } = groupApi.useGetActiveGroupQuery();
    const assignedGroupIds = useState<string[]>([]);

    const problemRequestsArray = selectedTasks;

    const { data: studentActiveData } = groupApi.useGetActiveGroupQuery();
    const assignedStudentIds = useState<string[]>([]);

    return (
        <CreateTaskFormStyled onSubmit={handleSubmit(onSubmit)}>
            <CreateTaskFormContainer>
                <CreateTaskFormField>
                    <label>
                        <BodyBigBoldText>
                            Тип задания <span style={{ color: '#F3163E' }}>*</span>
                        </BodyBigBoldText>
                    </label>
                    <TaskFormSelect
                        options={universityEventTypesDecriptions}
                        value={watch('type')}
                        onChange={e =>
                            setValue('type', universityEventTypesDecriptions[e.target.selectedIndex].name, {
                                shouldDirty: true,
                            })
                        }
                    />
                </CreateTaskFormField>

                <CreateTaskFormField>
                    <label>
                        <BodyBigBoldText>
                            Название задания <span style={{ color: '#F3163E' }}>*</span>
                        </BodyBigBoldText>
                    </label>
                    <TaskFormInput
                        {...register('name', { required: true })}
                        name="name"
                        placeholder="Введите название"
                        maxLength={50}
                        minLength={3}
                    />
                </CreateTaskFormField>

                <CreateTaskFormField>
                    <label>
                        <BodyBigBoldText>
                            Описание <span style={{ color: '#F3163E' }}>*</span>
                        </BodyBigBoldText>
                    </label>
                    <TaskFormTextAreaInput
                        {...register('description', { minLength: 5, required: true })}
                        name="description"
                        placeholder="Введите описание"
                        maxLength={300}
                        minLength={5}
                    />
                    <CountSymbols>
                        <BodySmallText
                            style={{
                                color: errors.description?.type === 'maxLength' ? 'red' : '#787676',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {watch('description').length}/300
                        </BodySmallText>
                    </CountSymbols>
                    <ChooseMinPointsComponent selectedTasks={selectedTasks} onMinPointsChange={handleMinPointsChange} />
                </CreateTaskFormField>

                <CreateTaskFormField>
                    <AssigneeContainerStyled>
                        <BodyBigBoldText>
                            Назначить <span style={{ color: '#F3163E' }}>*</span>
                        </BodyBigBoldText>

                        <RadioButtonStyled>
                            <TaskFormInput
                                type="radio"
                                name="assignee"
                                value="group"
                                checked={selectedAssignee === 'group'}
                                onChange={() => setSelectedAssignee('group')}
                                style={{ width: '16px', height: '16px' }}
                            />
                            <label>
                                <BodyBigRegularText>Курс</BodyBigRegularText>
                            </label>
                        </RadioButtonStyled>

                        {selectedAssignee === 'group' && (
                            <>
                                <SelectStyled multiple onChange={e => setSelectedGroup(e.target.value)}>
                                    {groupActiveData?.map(o => (
                                        <option key={o.id} value={o.id}>
                                            {o.name}
                                        </option>
                                    ))}
                                </SelectStyled>
                                <ChooseDateComponent onDatesChange={handleDatesChange} />
                            </>
                        )}

                        <RadioButtonStyled>
                            <TaskFormInput
                                type="radio"
                                name="assignee"
                                value="student"
                                checked={selectedAssignee === 'student'}
                                onChange={() => setSelectedAssignee('student')}
                                style={{ width: '16px', height: '16px' }}
                            />
                            <label>
                                <BodyBigRegularText>Студент</BodyBigRegularText>
                            </label>
                        </RadioButtonStyled>

                        {selectedAssignee === 'student' && (
                            <SelectStyled multiple onChange={() => {}}>
                                {studentActiveData?.map(o => (
                                    <option key={o.id} value={o.id}>
                                        {o.name}
                                    </option>
                                ))}
                            </SelectStyled>
                        )}
                    </AssigneeContainerStyled>
                </CreateTaskFormField>
                <ChooseDurationComponent onDurationChange={handleDurationChange} />
            </CreateTaskFormContainer>
            <ButtonStyled>
                <BlueButton type="submit" style={{ width: '200px', height: '40px' }}>
                    {'Сохранить'}
                </BlueButton>
            </ButtonStyled>
        </CreateTaskFormStyled>
    );
};
