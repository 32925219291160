import styled from 'styled-components';
import { BodyBaseBoldText } from '../../../styles/Text';
import { STATUS_COLORS } from '../../../styles/constants';

const ScoreText = styled(BodyBaseBoldText)<{ statusColor: string }>`
    color: ${props => props.statusColor};
`;

type TaskPageScoresProps = { userScore: number | undefined; maxScore: number | undefined };

const determineStatusColor = ({ userScore, maxScore }: TaskPageScoresProps) => {
    if (userScore === undefined || maxScore === undefined) return STATUS_COLORS['incorrect'];

    const scorePercent = (userScore / maxScore) * 100;

    if (scorePercent === 100) return STATUS_COLORS['correct'];
    if (scorePercent > 59) return STATUS_COLORS['partiallyCorrect'];
    if (scorePercent > 0) return STATUS_COLORS['mostlyCorrect'];

    return STATUS_COLORS['incorrect'];
};

export const TaskPageScores = ({ userScore, maxScore }: TaskPageScoresProps) => {
    const statusColor = determineStatusColor({ userScore, maxScore });
    const scoreString = `${userScore}/${maxScore}`;

    return <ScoreText statusColor={statusColor}>{scoreString}</ScoreText>;
};
