import React, { useMemo } from "react";
import styled from "styled-components"
import { INumberScrollProps } from "./types";
// @ts-ignore  
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrows/arrow-left.svg';
// @ts-ignore  
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrows/arrow-right.svg';

const ScrollContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: end;
    color: #333;
`

const Scroll = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    color: #787676;
`

const ScrollButton = styled.button`
    width: 30px;
    height: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: transparent;
    border: 0;
    padding: 0;

    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #DFDFDF;
    }
`

const ScrollActiveButton = styled.button`
    width: 30px;
    height: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 0;
    padding: 0;

    border-radius: 4px;
    background: #0B68FE;
    color: #FFF;
`

const Arrow = styled.button`
    background-color: transparent;
    border: 0;
    padding: 0;
    border-radius: 4px;

    &:enabled:hover {
        background-color: #DFDFDF;
        cursor: pointer;
    }
`

const Dots = styled.div`
    text-align: center;
    margin-top: auto;
    width: 30px;
`

function NumberScroll(props: INumberScrollProps) {
    const { dataSize, pageSize, activePage, changePage } = props;

    const pageCount = Math.ceil(dataSize / pageSize);

    const isLeftSkip = pageCount > 7 && activePage >= 5;
    const isRightSkip = pageCount > 7 && activePage < pageCount - 3;

    const pages = useMemo(() => {
        return ([
            1,
            isLeftSkip ? 0 : 2,
            isLeftSkip ? isRightSkip ? activePage - 1 : pageCount - 4 : 3,
            isLeftSkip ? isRightSkip ? activePage : pageCount - 3 : 4,
            isLeftSkip ? isRightSkip ? activePage + 1 : pageCount - 2 : 5,
            isRightSkip ? 0 : pageCount - 1,
            pageCount
        ]);
}, [activePage, isLeftSkip, isRightSkip, pageCount]);

    return (
        <ScrollContainer>
            <Arrow disabled={activePage === 1} onClick={() => changePage(activePage - 1)}>
                <ArrowLeft stroke={activePage === 1 ? '#E1E1E1' : '#787676'} />
            </Arrow>
            <Scroll>
                {pages.map((page, index) => {
                    if (page === 0) {
                        return <Dots key={index}>...</Dots>;
                    }
                    else if (activePage === page) {
                        return <ScrollActiveButton key={index}>{page}</ScrollActiveButton>;
                    }
                    else {
                        return <ScrollButton key={index} onClick={() => changePage(page)}>{page}</ScrollButton>;
                    }
                })
                }
            </Scroll>
            <Arrow disabled={activePage === pageCount} onClick={() => changePage(activePage + 1)}>
                <ArrowRight stroke={activePage === pageCount ? '#E1E1E1' : '#787676'} />
            </Arrow>
        </ScrollContainer>
    );
}

export default NumberScroll;