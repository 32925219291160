import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useActions } from "../../../../../hooks/useActions";
import { useAuth } from "../../../../../hooks/useAuth";
import { BlueButton } from "../../../../common/main/button/BlueButton";
import { Input } from "../../../../common/main/input/Input";
import { PasswordInput } from "../../../../common/main/input/PasswordInput";
import { AuthForm, AuthFormContainer, AuthFormField } from "../../../Styles";
import { AuthErrorMessage } from "../../../ui/AuthErrorMessage";
import { isuNumberValidation } from "../../../validation/isu-number.valid";
import { passwordValidation } from "../../../validation/password.valid";

export interface ILoginFields {
    isuNumber: number,
    password: string,
}

export const LoginForm: FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<ILoginFields>({
        mode: 'onChange'
    });

    const { login } = useActions();
    const { isLoading } = useAuth();

    const navigate = useNavigate();

    const onSubmit: SubmitHandler<ILoginFields> = data => {
        login(data)
    };

    return (
        <AuthForm onSubmit={handleSubmit(onSubmit)}>
            <AuthFormContainer>
                <AuthFormField>
                    <Input
                        {...register("isuNumber", isuNumberValidation)}
                        placeholder="Номер ИСУ"
                    />
                    <AuthErrorMessage>{errors.isuNumber?.message}</AuthErrorMessage>
                </AuthFormField>
                <AuthFormField>
                    <PasswordInput
                        {...register("password", passwordValidation)}
                        placeholder="Пароль"
                    />
                    <AuthErrorMessage>{errors.password?.message}</AuthErrorMessage>
                </AuthFormField>
            </AuthFormContainer>
            <BlueButton style={{ width: '250px' }} disabled={isLoading}>Войти</BlueButton>
        </AuthForm>
    );
};
