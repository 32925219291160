import { FC, MouseEventHandler } from "react";
import styled from "styled-components";
import { BodyBigBoldText, BodyBigRegularText } from "../../../../../styles/Text";
import { TabInfo } from "../Tabs";

const TabButton = styled.button<{ active: boolean }>`
    padding: 5px 10px;
    height: 34px;
    background-color: ${({ active }) => (active ? '#F0F4FD' : '#FAFAFA')};
    color: ${({ active }) => (active ? '#0B68FE' : '#333333')};
    border-radius: 6px;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: ${({ active }) => (active ? '#F0F4FD' : '#F4F4F4')};
    }
`;

interface TabProps {
    tab: TabInfo
    isActive: boolean
    handleClick: MouseEventHandler<HTMLButtonElement>
}

export const Tab: FC<TabProps> = ({ tab, isActive, handleClick }) => {
    return (
        <TabButton active={isActive} onClick={handleClick}>
            {isActive ?
                <BodyBigBoldText style={{ color: '#0B68FE' }}>{tab.name}</BodyBigBoldText> :
                <BodyBigRegularText style={{ color: '#787676' }}>{tab.name}</BodyBigRegularText>
            }
        </TabButton>
    )
}