import {TabInfo, Tabs} from "../../../../../../common/main/tabs/Tabs";
import React, {FC} from "react";

export enum TaskStatusTabType {
    Checked = 0,
    Unchecked = 1,
    NotStarted = 2
}

const tasksTabs: TabInfo[] = [
    {
        name: 'Проверенные задания'
    },
    {
        name: 'Непроверенные задания'
    },
    // {
    //     name: 'Не приступали'
    // }
]

export interface TabStatusTabsProps {
    activeTab: number,
    onActiveTabChange: (index: number) => void
}

export const TaskStatusTabs: FC<TabStatusTabsProps> = ({activeTab, onActiveTabChange}) => {
    return (
        <Tabs tabs={tasksTabs} activeTab={activeTab} handleClick={onActiveTabChange}/>
    )
}