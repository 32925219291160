import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { eventApi } from '../../../core/api/rtk-query/event.api';
import { groupApi } from '../../../core/api/rtk-query/group.api';
import { MainHeadingText } from '../../../styles/Text';
import { CoursesGrid } from '../../common/main/courses/CoursesGrid';
import { CoursesCardProps } from '../../common/main/courses/card/CourseCard';
import { TabInfo, Tabs } from '../../common/main/tabs/Tabs';

const calendarImg = require('../../../../src/assets/icons/main/calendar.svg').default;
const eventsImg = require('../../../../src/assets/icons/main/note.svg').default;

const cursesTabs: TabInfo[] = [
    {
        name: 'Активные курсы'
    },
    {
        name: 'Пройденные курсы'
    },
]

export const TeacherCoursesPage: FC = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [courses, setCourses] = useState<CoursesCardProps[] | undefined>(undefined);

    //TODO: подумать можно ли не грузить сразу активные и архивные группы
    const { data: groupActiveData, isLoading: groupActiveIsLoading } = groupApi.useGetActiveGroupQuery();
    const { data: groupArchivedData, isLoading: groupArchivedIsLoading } = groupApi.useGetArchivedGroupQuery();
    // TODO: подумать как показывать всех студентов (мб переместить в карточку)
    // const data = groupApi.useGetUsersInGroupQuery({_, 0, 0})
    const { data: eventsData, isLoading: eventsIsLoading } = eventApi.useGetEventsQuery()

    const isLoading = groupActiveIsLoading || groupArchivedIsLoading || eventsIsLoading;

    useEffect(() => {
        if (!isLoading) {
            const groupData = activeTab === 0 ? groupActiveData : groupArchivedData

            setCourses(
                groupData?.map((group) => ({
                    id: group.id,
                    name: group.name,
                    info: [
                        {
                            imgSrc: calendarImg,
                            condition: `${dayjs(group.startTime).format('DD.MM.YYYY')}-${dayjs(group.endTime).format('DD.MM.YYYY')}`
                        },
                        {
                            imgSrc: eventsImg,
                            condition: `${eventsData?.length} заданий`
                        }
                    ],
                }))
            )
        }
    }, [isLoading, activeTab])

    return (
        <>
            <MainHeadingText style={{ marginBottom: '30px' }}>Курсы</MainHeadingText>
            <Tabs
                tabs={cursesTabs}
                activeTab={activeTab}
                handleClick={(index) => setActiveTab(index)}
            />
            {courses &&
                <CoursesGrid repeat={4} courses={courses} />
            }
        </>
    );
};
