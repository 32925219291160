import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { BodyBaseBoldText, BodyBaseRegularText, BodyBigRegularText } from '../../../../../../styles/Text';
import { Score } from '../../../../../../hooks/useGetCurrentEventScore';
import { TasksSidebarItem } from '../Styles';
import { TaskPageScores } from '../../../../../common/tasks/TaskPageScores';

const doneImg = require('../../../../../../assets/icons/task-done.svg').default;

const TaskContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 21px;
    margin: 0 6px;
`;

const TaskButton = styled(BodyBigRegularText)`
    color: #0b68fe;
    cursor: pointer;
`;

interface TasksInfoProps {
    scores: Score[];
    isHideScores: boolean;
    isActive: boolean;
    isUserEventStillActive: boolean;
}

// TODO: добавить якоря
export const TasksInfo: FC<TasksInfoProps> = ({ scores, isHideScores, isActive, isUserEventStillActive }) => {
    const tasksInfo = useMemo(() => {
        if (scores.length === 0) {
            return null;
        }

        // если показываем напротив номера задания иконку галочки
        if (isHideScores && isActive) {
            return scores.map((score, index) => {
                return (
                    <TaskContainer key={index}>
                        <TaskButton>№{index + 1}</TaskButton>
                        {score.isSend && <img src={doneImg} alt="Сделано" />}
                    </TaskContainer>
                );
            });
        }
        // если показываем напротив номера задания сколько баллов получено по нему
        return scores.map(({ isSend, score, all }, index) => {
            return (
                <TaskContainer key={`${index}-${score}`}>
                    <TaskButton>№{index + 1}</TaskButton>
                    {(!isActive || isSend || !isUserEventStillActive) && <TaskPageScores userScore={score} maxScore={all} />}
                </TaskContainer>
            );
        });
    }, [scores]);

    return (
        <TasksSidebarItem>
            <BodyBaseRegularText style={{ color: '#787676' }}>Задачи</BodyBaseRegularText>
            {tasksInfo}
        </TasksSidebarItem>
    );
};
