import { requiredCapitalLetterRegex, requiredLowerCaseLetterRegex, requiredNumberRegex, requiredSpecialSymbolRegex } from "./auth.valid";

export const passwordValidation = {
    required: "Не указан пароль",
    minLength: {
        value: 8,
        message: "Пароль должен быть больше 8 символов"
    },
    maxLength: {
        value: 50,
        message: "Пароль должен быть не больше 50 символов"
    },
    validate: {
        capitalLetter: (value: string) => requiredCapitalLetterRegex.test(value)
            || "Пароль должен содержать заглавную букву",
        lowerCaseLetter: (value: string) => requiredLowerCaseLetterRegex.test(value)
            || "Пароль должен содержать строчную букву",
        number: (value: string) => requiredNumberRegex.test(value)
            || "Пароль должен содержать число",
        specialSymbol: (value: string) => requiredSpecialSymbolRegex.test(value)
            || "Пароль должен содержать спец символ: !?*.#_^$@№&",
    }
}