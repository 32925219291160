import React, {FC} from "react";
import {TeacherPageFixedHeader} from "../../Styles";
import {PersonalInformation} from "../../../common/header/PersonalInformation";
import {LogOut} from "../../../common/header/LogOut";

export const TeacherHeader: FC = () => {
    return (
        <TeacherPageFixedHeader>
            <PersonalInformation/>
            <LogOut/>
        </TeacherPageFixedHeader>
    )
}