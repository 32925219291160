import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BodyBigBoldText } from "../../../../../styles/Text";
import { BlueButton } from "../../button/BlueButton";
import { CardInfo } from "../../card/CardInfo";
import { CardInfoItemProps } from "../../card/info/CardInfoItem";
import { ProgressBar, ProgressBarProps } from "../../progressbar/ProgressBar";

const CourseCardContainer = styled.div`
    height: 292px;

    background-color: #FFFFFF;
    box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
`

const CoursesCardContent = styled.div`
    box-sizing: border-box;
    padding: 30px 20px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const CourseCardDiv = styled.div`
    display: flex;
    flex-direction: column;
`

export interface CoursesCardProps {
    id: string
    name: string
    info: CardInfoItemProps[]
    point?: ProgressBarProps
}

export const CourseCard: FC<CoursesCardProps> = ({ id, name, info, point }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleClick = () => {
        navigate(`${pathname}/${id}`)
    }

    return (
        <CourseCardContainer>
            <CoursesCardContent>
                <CourseCardDiv style={{ gap: '10px' }}>
                    <BodyBigBoldText>{name}</BodyBigBoldText>
                    <CardInfo conditions={info} />
                </CourseCardDiv>
                <CourseCardDiv style={{ gap: '25px' }}>
                    {point && (
                        <ProgressBar {...point} />
                    )}
                    <BlueButton
                        style={{ width: '250px', alignSelf: 'center' }}
                        onClick={handleClick}
                    >
                        Продолжить
                    </BlueButton>
                </CourseCardDiv>
            </CoursesCardContent>
        </CourseCardContainer>
    )
}
