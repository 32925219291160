import styled from "styled-components";

const StudentCoursesWrapper = styled.div`
    width: 100%;
    flex: 1;
`

const RatedStatusBg = styled.div`
    width: 65px;
    height: 24px;
    margin: 3px 0;
    border-radius: 4px;
    background-color: #D1FFD6;
`

const NotRatedStatusBg = styled.div`
    width: 96px;
    height: 24px;
    margin: 3px 0;
    border-radius: 4px;
    background-color: #EAF0F9;
`

export {
    StudentCoursesWrapper
};

