import React, {FC} from "react";
import {NavElementContainer, NavElementText, StyledNavLink} from "../../../../Styles";

type NavElementProps = {
    title: string,
    path: string,
    iconPath: string
}

export const NavElement: FC<NavElementProps> = ({title, path, iconPath}) => {
    return (
        <NavElementContainer>
            <StyledNavLink to={path}>
                <img src={iconPath} alt={"icon"}/>
                <NavElementText>{title}</NavElementText>
            </StyledNavLink>
        </NavElementContainer>
    )
}
