import React from 'react';
import { ICellProps } from './types';

export function renderCell<T>(
    row: T,
    idx: number,
    elem: React.ReactNode | ((rowData: T, rowIndex: number) => React.ReactNode)
) {
    let element: React.ReactElement | undefined;

    if (elem instanceof Function) {
        element = <>{elem(row, idx)}</>;
    } else {
        element = <>{elem}</>;
    }

    return element;
}

function Cell<T>(props: ICellProps<T>): React.ReactElement {
    return <>{props.children}</>;
}

export default Cell;
