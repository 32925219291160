import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { BlueButton } from '../main/button/BlueButton';
import { WhiteButton } from '../main/button/WhiteButton';
import { eventApi } from '../../../core/api/rtk-query/event.api';

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;

const WhiteButtonStyled = styled(WhiteButton)`
    width: 250px;
    align-self: center;
`;

const BlueButtonStyled = styled(BlueButton)`
    width: 250px;
    align-self: center;
`;

interface ITaskPageFooterProps {
    eventId: string | undefined;
    isUserEventStillActive: boolean;
}

export const TaskPageFooter = ({ eventId, isUserEventStillActive }: ITaskPageFooterProps) => {
    const navigate = useNavigate();
    const [finishUserEvent] = eventApi.useFinishUserEventMutation();

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleFinishClick = () => {
        if (eventId) {
            finishUserEvent({ eventId });
            toast.success('Вы завершили задание');
            navigate(-1);
        }
    };

    return (
        <ButtonContainer>
            <WhiteButtonStyled onClick={handleBackClick}>Назад</WhiteButtonStyled>
            {isUserEventStillActive && (
                <BlueButtonStyled onClick={handleFinishClick}>Завершить задание</BlueButtonStyled>
            )}
        </ButtonContainer>
    );
};
