import { Role } from "../core/types/user.interface";
import { ROUTES } from "../routes/routes.config";
import { useAuth } from "./useAuth";

export const useRouting = () => {
    const { token, role } = useAuth()

    var mainPage = ROUTES.auth.login.fullPath

    if (role === Role.Student) {
        mainPage = ROUTES.student.courses.fullPath
    }
    if (
        role === Role.Teacher ||
        role === Role.Mentor
    ) {
        mainPage = ROUTES.teacher.courses.fullPath
    }
    if (role === Role.Admin) {
        //TODO: add admin routes
    }

    return {
        isAuth: token ? true : false,
        role: role,
        mainPage,
    }
}