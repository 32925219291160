import { FC } from 'react';
import styled from 'styled-components';
import { IProblem, IProblemAttempts } from '../../../../../../core/types/problem.interface';
import { Score } from '../../../../../../hooks/useGetCurrentEventScore';
import { TaskContent } from './ui/TaskContent';
import { TaskFooter } from './ui/TaskFooter';
import { TaskHeader } from './ui/TaskHeader';

const CardContainer = styled.div`
    display: flex;
    padding: 5px 40px 30px 40px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.06);
`;

interface TaskCardProps {
    problem: IProblem;
    attempt: IProblemAttempts | undefined;
    index: number;
    score: Score;
    isUserEventStillActive: boolean;
}

export const TaskCard: FC<TaskCardProps> = ({ attempt, index, score, isUserEventStillActive }) => {
    return (
        <>
            {attempt && (
                <CardContainer>
                    <TaskHeader taskNum={index} isUserEventStillActive={isUserEventStillActive} score={score} />
                    <TaskContent content={attempt.condition} />
                    <TaskFooter attempt={attempt} isUserEventStillActive={isUserEventStillActive} score={score} />
                </CardContainer>
            )}
        </>
    );
};
