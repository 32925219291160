import styled from "styled-components";

const TasksSidebarItem = styled.div`
    height: fit-content;

    padding: 10px 15px;
    border-radius: 6px;
    box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.06);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;

export {
    TasksSidebarItem
};

