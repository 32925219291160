import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthPage } from '../components/auth/AuthPage';
import { LoginPage } from '../components/auth/login/LoginPage';
import { RegisterPage } from '../components/auth/register/RegisterPage';
import { StudentPage } from '../components/student/StudentPage';
import { StudentCoursesPage } from '../components/student/courses/StudentCoursesPage';
import { StudentCursePage } from '../components/student/courses/course/StudentCoursePage';
import { StudentTaskPage } from '../components/student/tasks/StudentTaskPage';
import { TeacherPage } from '../components/teacher/TeacherPage';
import { TeacherCoursesPage } from '../components/teacher/courses/TeacherCoursesPage';
import { TeacherCoursePage } from '../components/teacher/courses/course/TeacherCoursePage';
import { TeacherStudentsPage } from '../components/teacher/students/TeacherStudentsPage';
import { TeacherStudentPage } from '../components/teacher/students/student/TeacherStudentPage';
import { TeacherTasksPage } from '../components/teacher/tasks/TeacherTasksPage';
import { CreateTaskPage } from '../components/teacher/tasks/create/CreateTaskPage';
import { EditTaskPage } from '../components/teacher/tasks/edit/EditTaskPage';
import { TeacherTaskPage } from '../components/teacher/tasks/task/TeacherTaskPage';
import { Role } from '../core/types/user.interface';
import { useRouting } from '../hooks/useRouting';
import { ROUTES } from './routes.config';

type ProtectedRouteProps = {
    isAuth: boolean
    isAllowed: boolean
    path: string
    outlet: JSX.Element
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({ isAuth, isAllowed, path, outlet }) => {
    if (isAuth) {
        if (isAllowed) {
            return outlet;
        } else {
            return <Navigate to={path} replace />;
        }
    } else {
        return <Navigate to={ROUTES.auth.login.fullPath} replace />;
    }
}

type AuthRouteProps = {
    isAuth: boolean
    path: string
    outlet: JSX.Element
};

const AuthRoute: FC<AuthRouteProps> = ({ isAuth, path, outlet }) => {
    if (!isAuth) {
        return outlet;
    } else {
        return <Navigate to={path} replace />;
    }
}

export const useRoutes = () => {
    const { isAuth, mainPage, role } = useRouting();

    return (
        <Routes>
            {/* authorization */}
            <Route path={ROUTES.auth.path} element={
                // <AuthPage />
                <AuthRoute
                    isAuth={isAuth}
                    path={mainPage}
                    outlet={<AuthPage />}
                />
            }
            >
                <Route path={ROUTES.auth.login.path} element={<LoginPage />} />
                <Route
                    path={ROUTES.auth.register.path}
                    element={<RegisterPage />}
                />
                <Route
                    path='*'
                    element={<Navigate to={ROUTES.auth.login.fullPath} />}
                />
            </Route>

            {/* student */}
            <Route
                path={ROUTES.student.path}
                element={
                    <ProtectedRoute
                        isAuth={isAuth}
                        isAllowed={role === Role.Student}
                        path={mainPage}
                        outlet={<StudentPage />}
                    />
                }
            >
                <Route
                    path={ROUTES.student.courses.path}
                    element={<StudentCoursesPage />}
                />
                <Route
                    path={ROUTES.student.currentCourse.path}
                    element={<StudentCursePage />}
                />
                <Route
                    path={ROUTES.student.currentTask.path}
                    element={<StudentTaskPage />}
                />
                <Route
                    path='*'
                    element={<Navigate to={ROUTES.student.courses.fullPath} />}
                />
            </Route>

            {/* teacher */}
            <Route
                path={ROUTES.teacher.path}
                element={
                    <ProtectedRoute
                        isAuth={isAuth}
                        isAllowed={role === Role.Teacher}
                        path={mainPage}
                        outlet={<TeacherPage />}
                    />
                }
            >
                <Route
                    path={ROUTES.teacher.courses.path}
                    element={<TeacherCoursesPage />}
                />
                <Route
                    path={ROUTES.teacher.currentCourse.path}
                    element={<TeacherCoursePage />}
                />
                <Route
                    path={ROUTES.teacher.students.path}
                    element={<TeacherStudentsPage />}
                />
                <Route
                    path={ROUTES.teacher.currentStudent.path}
                    element={<TeacherStudentPage />}
                />
                <Route
                    path={ROUTES.teacher.tasks.path}
                    element={<TeacherTasksPage />}
                />
                <Route
                    path={ROUTES.teacher.currentTask.path}
                    element={<TeacherTaskPage />}
                />
                <Route
                    path={ROUTES.teacher.editTask.path}
                    element={<EditTaskPage />}
                />
                <Route
                    path={ROUTES.teacher.createTask.path}
                    element={<CreateTaskPage />}
                />
                <Route
                    path='*'
                    element={<Navigate to={ROUTES.teacher.courses.fullPath} />}
                />
            </Route>

            <Route path='*' element={<Navigate to={mainPage} />} />
        </Routes>
    );
};
