import { FC } from 'react';
import { BodyBaseRegularText, BodyBigRegularText } from '../../../../../../styles/Text';
import { TasksSidebarItem } from '../Styles';

interface MarksEarnedProps {
    all: number;
    done: number;
}

export const MarksEarned: FC<MarksEarnedProps> = ({ all, done }) => {
    return (
        <TasksSidebarItem>
            <BodyBaseRegularText style={{ color: '#787676' }}>
                Получено баллов
            </BodyBaseRegularText>
            <BodyBigRegularText style={{ color: '#333' }}>
                {`${done}/${all}`}
            </BodyBigRegularText>
        </TasksSidebarItem>
    );
};
