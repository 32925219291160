import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { eventApi } from '../../../../core/api/rtk-query/event.api';
import { EventType, IEvent } from '../../../../core/types/event.interface';
import { useGetCurrentGroup } from '../../../../hooks/api/useGetCurrentGroup';
import { MainHeadingText, SecondaryHeadingTextForEmptyStates } from '../../../../styles/Text';
import { getInfo, getMaxPoints, getUserPoints } from '../../../../utils/cards.utils';
import { getDayjs, getDayjsInGMT, getDuration } from '../../../../utils/dayjs.utils';
import { EventCardProps } from '../../../common/main/events/card/EventCard';
import { TabInfo, Tabs } from '../../../common/main/tabs/Tabs';
import { IEventCardProps } from '../../../common/main/events/card/types';
import { EventsGrid } from '../../../common/main/events/EventsGrid';

const eventCategoryTabs: TabInfo[] = [
    {
        name: 'Домашние работы'
    },
    {
        name: 'Контрольные работы'
    },
    {
        name: 'Рубежные работы'
    },
    {
        name: 'Экзамен'
    }
];

const StudentEventsWrapper = styled.div`
    width: 100%;
    flex: 1;
`;

type StudentEventsContainerProps = {isLoading: boolean, eventCards: EventCardProps[] | undefined};

const StudentEventsContainer = ({isLoading, eventCards}: StudentEventsContainerProps) => {
    if (isLoading) {
        return <SecondaryHeadingTextForEmptyStates>ЗАГРУЗКА</SecondaryHeadingTextForEmptyStates>;
    }

    if (!eventCards?.length) {
        return <SecondaryHeadingTextForEmptyStates>Здесь пока нет заданий</SecondaryHeadingTextForEmptyStates>;
    }

    return (
        <StudentEventsWrapper>
            {eventCards && <EventsGrid repeat={3} events={eventCards} />}
        </StudentEventsWrapper>
    );
}

export const StudentCursePage: FC = ({}) => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [eventCards, setEventCards] = useState<EventCardProps[] | undefined>(undefined);

    const { group, isLoading: userIsLoading } = useGetCurrentGroup();
    const { data: events, isLoading: eventsIsLoading } = eventApi.useGetEventsQuery();
    const { data: userEvents, isLoading: userEventsIsLoading } = eventApi.useGetUserEventsQuery();
    const isLoading = userIsLoading || eventsIsLoading || userEventsIsLoading;

    const getEvent = (event: IEvent): EventCardProps => {
        const startEvent = getDayjs(event.startTime);
        const endEvent = getDayjs(event.endTime);

        const info = getInfo(
            {
                start: startEvent,
                end: endEvent
            },
            event.problems.length,
            activeTab !== 0 ? event.duration : undefined
        );

        let whiteButtonText: string | undefined = undefined;
        if (endEvent < getDayjs()) {
            whiteButtonText = 'Смотреть';
        }
        const userEvent = userEvents?.find(userEvent => userEvent.eventId === event.id);
        if (userEvent && EventType[event.type] !== EventType.Homework) {
            const start = getDayjsInGMT(userEvent.startTime);
            const duration = getDuration(event.duration);
            const now = getDayjsInGMT();
            if (duration - now.diff(start) <= 0) {
                whiteButtonText = 'Смотреть';
            }
        }

        let blueButtonText: string | undefined = undefined;
        if (startEvent < dayjs()) {
            if (!whiteButtonText) {
                if (userEvents?.map(userEvent => userEvent.eventId).includes(event.id)) {
                    blueButtonText = 'Продолжить';
                } else {
                    blueButtonText = 'Начать';
                }
            }
        }

        const point = {
            name: 'Получено баллов',
            max: getMaxPoints(event),
            now: getUserPoints(userEvents?.find(userEvent => userEvent.eventId === event.id))
        };

        return {
            id: event.id,
            name: event.name,
            description: event.description,
            info,
            point,
            buttons: {
                blueButtonText,
                whiteButtonText
            }
        };
    };

    useEffect(() => {
        if (!isLoading) {
            setEventCards(
                events?.filter(event => event.groupId === group?.id && event.type === Object.keys(EventType)[activeTab]).map(event => getEvent(event))
            );
        }
    }, [isLoading, activeTab]);

    return (
        <>
            {group && <MainHeadingText style={{ marginBottom: 30 }}>{group.name}</MainHeadingText>}
            <Tabs tabs={eventCategoryTabs} activeTab={activeTab} handleClick={index => setActiveTab(index)} />
            <StudentEventsContainer isLoading={isLoading} eventCards={eventCards}/>
        </>
    );
};
