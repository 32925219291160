import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { EventType } from '../../../core/types/event.interface';
import { useGetCurrentEvent } from '../../../hooks/api/useGetCurrentEvent';
import { MainHeadingText, BodyBigRegularText } from '../../../styles/Text';
import { getDayjs, getDayjsInGMT, getDuration } from '../../../utils/dayjs.utils';
import { TasksSidebar } from './ui/sidebar/TasksSidebar';
import { TasksList } from './ui/task/TasksList';
import { useGetCurrentEventScore } from '../../../hooks/useGetCurrentEventScore';
import { useGetCurrentEventTimeInfo } from '../../../hooks/useGetCurrentEventTimeInfo';
import { Dayjs } from 'dayjs';

const StudentTasksMain = styled.div`
    width: 100%;
    margin: 30px 0 50px;
    display: flex;
    flex-direction: row;
    gap: 20px;
`;

type setIsActiveProps = {
    isHomework: boolean;
    now: Dayjs;
    userEventActivationDate: Dayjs | null;
    eventDurationAfterActivation: number | null;
    eventEndDate: Dayjs | null;
};
const getIsActive = ({
    isHomework,
    now,
    userEventActivationDate,
    eventDurationAfterActivation,
    eventEndDate,
}: setIsActiveProps) => {
    if (userEventActivationDate === null || eventDurationAfterActivation === null || eventEndDate === null)
        return false;
    const isHomeworkInProgress = isHomework && eventEndDate > getDayjs();
    const isEventInProgress = now.diff(userEventActivationDate) < eventDurationAfterActivation;
    const isExamInProgress = !isHomework && isEventInProgress;

    return isHomeworkInProgress || isExamInProgress;
};

export const StudentTaskPage: FC = () => {
    const { event, userEvent, isLoading } = useGetCurrentEvent();
    const { scores } = useGetCurrentEventScore();
    const { now, userEventActivationDate, eventDurationAfterActivation, eventEndDate, isUserEventStillActive } =
        useGetCurrentEventTimeInfo();

    if (isLoading || (!event && !userEvent)) return <MainHeadingText>Загрузка</MainHeadingText>;

    const isHomework = EventType[event!.type] === EventType.Homework;
    const isActive = getIsActive({
        isHomework,
        now,
        userEventActivationDate,
        eventDurationAfterActivation,
        eventEndDate,
    });

    return (
        <>
            <MainHeadingText>{event?.name}</MainHeadingText>
            <BodyBigRegularText style={{ marginTop: '10px' }}>{event?.description}</BodyBigRegularText>
            <StudentTasksMain>
                <TasksList
                    attempts={userEvent?.problemAttempts}
                    problems={event?.problems}
                    scores={scores}
                    eventId={event?.id}
                    isUserEventStillActive={isUserEventStillActive}
                />
                {scores && <TasksSidebar scores={scores} isActive={isActive} />}
            </StudentTasksMain>
        </>
    );
};
