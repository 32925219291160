import {FC, PropsWithChildren} from "react";
import styled from "styled-components";

const PopupFormStyled = styled.div`
    width:100vw;
    height:100vh;
    background-color: rgba(0,0,0,0.3);
    position:fixed;
    top:0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    z-index: 1000;
`

const PopupFormContentStyled = styled.div`
    width: 1273px;
    height: 100%;
    position: relative;
    padding: 35px;
    background-color: #FAFAFA;
    border-radius:5px;
    flex-direction: column;
    align-items: center;
    display: flex;
    overflow-y:auto;
    z-index: 1001;
    margin: 10px;
`

interface PopupProps {
    style?: object;
}

export const Popup: FC<PropsWithChildren<PopupProps>> = ({
    children,style
}) => {
    return (
        <PopupFormStyled>
            <PopupFormContentStyled>
                {children}
            </PopupFormContentStyled>
        </PopupFormStyled>
    )
}