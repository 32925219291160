import { FC } from "react";
import styled from "styled-components";
import { EventCard, EventCardProps } from "./card/EventCard";

const EventGridStyled = styled.div<Pick<EventGridProps, 'repeat'>>`
    width: 100%;
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(${({ repeat }) => repeat}, 1fr);
`

interface EventGridProps {
    events: EventCardProps[]
    repeat: number
}

export const EventsGrid: FC<EventGridProps> = ({ events, repeat }) => {
    return (
        <EventGridStyled repeat={repeat}>
            {events.map((events, index) => {
                return (
                    <EventCard
                        key={index}
                        {...events}
                    />
                )
            })}
        </EventGridStyled>
    )
}
