import { FC, useEffect } from 'react';
import toast, { ToastBar, Toaster, resolveValue, useToasterStore } from 'react-hot-toast';
import styled from "styled-components";
import { BodyBaseRegularText } from '../../../styles/Text';

const infoBackground: string = require("../../../assets/images/toast/toast-background-blue.svg").default;
const successBackground: string = require("../../../assets/images/toast/toast-background-green.svg").default;
const errorBackground: string = require("../../../assets/images/toast/toast-background-red.svg").default;

const info: string = require("../../../assets/icons/toast/info.svg").default;
const success: string = require("../../../assets/icons/toast/success.svg").default;
const error: string = require("../../../assets/icons/toast/error.svg").default;

const ToastBody = styled.div`
    width: 340px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
`

const ToastMain = styled.div`
    flex: 93.4%;
    color: #333;
    margin: 20px 15px 15px;

    display: flex;
    flex-direction: row;
    gap: 10px;
`

const ToastImg = styled.img`
    margin-top: 2px;
    width: 22.5px;
    height: 22.5px;
`

interface ToastBackgroundStyled {
    backgroundImage: string
}

const ToastBackground = styled.div<ToastBackgroundStyled>`
    flex: 6.6%;

    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    background-image: url(${(p) => p.backgroundImage});
    background-repeat: repeat-y;
    background-size: 100%;
`

interface CustomToasterProps {
    top: string;
}

export const CustomToaster: FC<CustomToasterProps> = ({ top }) => {
    const TOAST_LIMIT = 5

    const { toasts } = useToasterStore();

    useEffect(() => {
        toasts
            .filter((t) => t.visible)
            .filter((_, i) => i >= TOAST_LIMIT)
            .forEach((t) => toast.dismiss(t.id));
    }, [toasts]);

    const getBackground = (type: string) => {
        switch (type) {
            case "error":
                return errorBackground;
            case "blank":
                return infoBackground;
            case "success":
                return successBackground;
            default:
                return '';
        }
    }

    const getImg = (type: string) => {
        switch (type) {
            case "error":
                return error;
            case "blank":
                return info;
            case "success":
                return success;
            default:
                return '';
        }
    }

    return (
        <Toaster
            position="top-right"
            reverseOrder={false}
            gutter={15}
            containerStyle={{
                zIndex: '100',
                position: 'sticky',
                top: `calc(15px + ${top})`,
                right: '15px',
            }}
            toastOptions={{
                className: '',
                duration: 10000,
                style: {
                    boxShadow: '0px 4px 7px 1px rgba(0, 0, 0, 0.06)',
                    borderRadius: '4px',
                    padding: '0',
                    width: '100%',
                },
            }}
        >
            {(t) => (
                <ToastBar toast={t}>
                    {() => (
                        <ToastBody onClick={() => toast.dismiss(t.id)}>
                            <ToastBackground backgroundImage={getBackground(t.type)} />
                            <ToastMain>
                                <ToastImg src={getImg(t.type)} />
                                <BodyBaseRegularText>
                                    {resolveValue(t.message, t)}
                                </BodyBaseRegularText>
                            </ToastMain>
                        </ToastBody>
                    )}
                </ToastBar>
            )}
        </Toaster>
    )
};
