import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import styled from "styled-components";
import { eventApi } from '../../../../core/api/rtk-query/event.api';
import { EventType, IEvent } from '../../../../core/types/event.interface';
import { useGetCurrentGroup } from '../../../../hooks/api/useGetCurrentGroup';
import { MainHeadingText } from "../../../../styles/Text";
import { TabInfo, Tabs } from "../../../common/main/tabs/Tabs";
import { AddNewEventButton } from "./ui/AddNewEventButton";
import { EventsGrid } from '../../../common/main/events/EventsGrid';
import { EventCardProps } from '../../../common/main/events/card/EventCard';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../routes/routes.config';

const calendarImg = require('../../../../../src/assets/icons/main/calendar.svg').default;
const problemImg = require('../../../../../src/assets/icons/main/note.svg').default;
const timeLimitImg = require('../../../../../src/assets/icons/main/time.svg').default;
const newTaskImg = require('../../../../../src/assets/icons/main/plus.svg').default;

const eventCategoryTabs: TabInfo[] = [
    {
        name: 'Домашние работы'
    },
    {
        name: 'Контрольные работы'
    },
    {
        name: 'Рубежные работы'
    },
    {
        name: 'Экзамен'
    }
]

const StudentEventsContainer = styled.div`
    width: 100%;
    flex: 1;
`

const TeacherCourseContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
`

export const TeacherCoursePage: FC = ({ }) => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [events, setEvents] = useState<EventCardProps[] | undefined>(undefined);

    const { group, isLoading: userIsLoading } = useGetCurrentGroup()
    const { data: eventsData, isLoading: eventsIsLoading } = eventApi.useGetEventsQuery()
    // TODO: подумать как получить всех студентов (мб переместить в карточку)
    // const { data: usersInEventData, isLoading: usersInEventIsLoading } = eventApi.useGetUsersInEventQuery(_, 0, 0)
    // const { data: usersNotStartedData, isLoading: usersNotStartedIsLoading } = eventApi.useGetUsersWhoNotStartedQuery(_, 0, 0)
    const isLoading = userIsLoading || eventsIsLoading;

    const navigate = useNavigate();

    const getEvent = (event: IEvent): EventCardProps => {
        const info = [
            {
                imgSrc: calendarImg,
                condition: `${dayjs(event.startTime).format('DD.MM.YYYY')}-${dayjs(event.endTime).format('DD.MM.YYYY')}`
            },
            {
                imgSrc: problemImg,
                condition: `${event.problems.length} задача`
            }
        ]
        if (activeTab !== 0) {
            info.push({
                imgSrc: timeLimitImg,
                condition: `${dayjs(event.duration).format('HH:MM')}`
            })
        }

        return {
            id: event.id,
            name: event.name,
            description: event.description,
            info,
            buttons: {
                whiteButtonText: 'Редактировать',
                blueButtonText: 'Результаты',
            }
        }
    }

    useEffect(() => {
        if (!isLoading) {
            setEvents(
                eventsData
                    ?.filter(event => event.type ===  Object.keys(EventType)[activeTab])
                    .map((event) => (getEvent(event)))
            )
        }
    }, [isLoading, activeTab])

    const handleCreateClick = () => {
        navigate(ROUTES.teacher.createTask.fullPath);
    }

    return (
        <>
            <TeacherCourseContainer>
                {group && <MainHeadingText style={{ marginBottom: '10px' }}>{group.name}</MainHeadingText>}
                <AddNewEventButton imgSrc={newTaskImg} name={"Новое задание"} onClick={handleCreateClick}></AddNewEventButton>
            </TeacherCourseContainer>
            <StudentEventsContainer style={{ gap: '25px' }}>
                <Tabs
                    tabs={eventCategoryTabs}
                    activeTab={activeTab}
                    handleClick={(index) => setActiveTab(index)}
                />
                {events && <EventsGrid repeat={4} events={events} />}
            </StudentEventsContainer>
        </>
    );
};
