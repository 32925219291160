import React, { FC, useState } from "react";
import { TaskResultsContainer, TaskResultsText } from "../../Styles";
import { TaskStatusTabs, TaskStatusTabType } from "./tabs/TaskStatusTabs";
import { StudentSearchBar } from "./searchbar/StudentSearchBar";
import { IProblem } from "../../../../../../core/types/problem.interface";
import { StudentResultsTable } from "./table/StudentsResultsTable";

interface TaskResultsProps {
    id: string,
    problems: IProblem[]
}

export const TaskResults: FC<TaskResultsProps> = (props) => {

    const [filterText, setFilterText] = useState('');
    const [activeTab, setActiveTab] = useState<number>(TaskStatusTabType.Checked);
    const [pageNumber, setPageNumber] = useState(16);
    const [pageSize, setPageSize] = useState(12);
    const [totalItems, setTotalItems] = useState(240);

    const handleFilterTextChange = (text: string) => {
        setFilterText(text);
    };

    const handleActiveTabChange = (activeTab: number) => {
        setActiveTab(activeTab);
    }

    const handlePageChange = (pageNumber: number) => {
        setPageNumber(pageNumber)
        console.log(pageNumber)
    }

    const totalPages = Math.ceil(totalItems / pageSize)

    return (
        <TaskResultsContainer>
            <TaskResultsText>Результаты</TaskResultsText>
            <StudentSearchBar onFilterTextChange={handleFilterTextChange} />
            <TaskStatusTabs activeTab={activeTab} onActiveTabChange={handleActiveTabChange} />
            <StudentResultsTable
                {...props}
                activeTab={activeTab}
                filterText={filterText}
                currentPage={pageNumber}
                itemsPerPage={pageSize}
            />
        </TaskResultsContainer>
    )
}