import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(timezone)
dayjs.extend(utc)

function getDayjs(): Dayjs;
function getDayjs(date: string): Dayjs;
function getDayjs(date?: string) {
    if (date) {
        return dayjs(date, "YYYY-MM-DDTHH:mm:ss");
    }
    else {
        return dayjs();
    }
}

function getDayjsInGMT(): Dayjs;
function getDayjsInGMT(date: string): Dayjs;
function getDayjsInGMT(date?: string) {
    if (date) {
        return dayjs.tz(date, "YYYY-MM-DDTHH:mm:ss", "GMT+0");
    }
    else {
        return dayjs().tz("GMT+0");
    }
}

function getDueDate(start: Dayjs, end: Dayjs): string {
    return start.format("DD.MM.YYYY") + " - " + end.format("DD.MM.YYYY");
}

function getDuration(duration: string): number {
    const durationDayjs = dayjs(duration, "HH:mm:ss")
    return dayjs.duration({
        hours: durationDayjs.hour(),
        minutes: durationDayjs.minute(),
        seconds: durationDayjs.second()
    }).asMilliseconds();
}

function getDurationInString(duration: string): string {
    return dayjs(duration, "HH:mm:ss").format("HH:mm");
}

const isTimeValid = (time: string) => dayjs(time, 'HH:mm', true).isValid();
const isDateValid = (date: string) => dayjs(date, 'DD.MM.YYYY', true).isValid();

export {
    getDayjs,
    getDayjsInGMT,
    getDueDate,
    getDuration,
    getDurationInString,
    isTimeValid,
    isDateValid
};

