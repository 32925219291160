import { IBase, IPagination } from "./base.interface";
import { IGroup } from "./group.interface";
import { IProblemScores } from "./problem.interface";

export enum Role {
    Student,
    Mentor,
    Teacher,
    Admin,
}

export interface IUser extends IBase {
    isuNumber: number,
    email: string,
    name: string,
    role: keyof typeof Role,
    isuGroup: string,
    groups: IGroup[]
}

export interface IUserAttempt {
    isuNumber: string
    name: string
    userId: string
    problemScores: IProblemScores[]
}

export interface IUsers extends IPagination {
    users: IUser[]
}

export interface IUserAttempts extends IPagination {
    userAttempts: IUserAttempt[]
}