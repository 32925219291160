import { onlyNumbersRegex } from "./auth.valid";

export const isuNumberValidation = {
    required: "Не указан номер ИСУ",
    min: {
        message: "Номер ИСУ должен быть больше 0",
        value: 0
    },
    pattern: {
        value: onlyNumbersRegex,
        message: "Номер ИСУ должен состоять только из цифр"
    }
}