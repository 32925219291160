import styled from "styled-components";
import { ButtonBigText } from "../../../../styles/Text";

export const BlueButton = styled(ButtonBigText)`
    height: 40px;

    padding: 8px;
    border-radius: 4px;
    border: none;

    background-color: #0B68FE;
    color: #FFFFFF;

    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: #0962F2;
    }

    &:active {
        background-color: #035BDF;
    }
`