import React, { useMemo, useState } from "react";
import { ITableProps } from "./types";
import Column, { convertColumn } from "./Column";
import Cell, { renderCell } from "./Cell";
import HeaderCell from "./HeaderCell";
import styled from "styled-components";
import NumberScroll from "../Scroll/NumberScroll/NumberScroll";

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
`

const TableStyled = styled.div`
    width: 100%;
    box-sizing: border-box;

    display: grid;
    grid-template-rows: repeat(auto-fill, minmax(46px, auto));
    grid-template-columns: 1fr;

    border-radius: 6px;
    border: 1px solid #E1E1E1;
`;

const TableHeader = styled.div`
    box-sizing: border-box;
    width: 100%;

    display: grid;
    grid-template-rows: 1fr;

    padding: 10px 20px;
    background: #DFDFDF;
    color: #787676;
`;

const TableRow = styled.div`
    width: 100%;
    box-sizing: border-box;

    display: grid;
    grid-template-rows: 1fr;

    padding: 10px 20px;
    border: 1px solid #E1E1E1;
    color: #333;
`;

function Table<T extends { id: string }>(props: ITableProps<T>) {
    const data: T[] = useMemo(() => props.data || [], [props.data]);

    const [currentPage, setCurrentPage] = useState<number>(1);

    const columns: Array<{
        header: React.ReactElement;
        cell: React.ReactElement;
        width?: string;
    }> = [];

    if (props.children) {
        if (props.children instanceof Array) {
            for (const item of props.children) {
                const column = convertColumn(item);
                column && columns.push(column);
            }
        } else {
            const column = convertColumn(props.children);
            column && columns.push(column);
        }
    }

    let gridTemplateColumns = '';

    for (const column of columns) {
        if (column.width) {
            gridTemplateColumns += ' ' + column.width;
        } else {
            gridTemplateColumns += ' 1fr';
        }
    }

    const renderTableHeader = () => {
        return (
            <TableHeader
                key="header"
                style={{ gridTemplateColumns }}
            >
                {columns.map((column, index) => (
                    <div key={index}>
                        {column.header}
                    </div>
                ))}
            </TableHeader>
        );
    };

    const renderRow = (rowData: T, idx: number) => {
        let rowStyles: React.CSSProperties = {};
        if (props.rowStyle) {
            rowStyles = props.rowStyle(rowData, idx);
        }

        return (
            <TableRow
                key={`row-${rowData.id}`}
                style={{ gridTemplateColumns, ...rowStyles }}
            >
                {columns.map((column, columnIdx) => (
                    <div key={`row-${rowData.id}-column-${columnIdx}`}>
                        {renderCell(rowData, idx, column.cell.props.children)}
                    </div>
                ))}
            </TableRow>
        );
    };

    const tableStyle: React.CSSProperties = { minWidth: '700px' };

    return (
        <TableContainer>
            <TableStyled
                style={{
                    ...tableStyle,
                    gap: props.gap,
                }}
            >
                {!props.hideHeader && renderTableHeader()}
                {data.map((rowData, idx) => renderRow(rowData, idx))}
            </TableStyled>
            {props.dataSize && props.pageSize &&
                <NumberScroll dataSize={props.dataSize} pageSize={1} activePage={currentPage} changePage={(page) => setCurrentPage(page)} />
            }
        </TableContainer>
    );
}

Table.Column = Column;
Table.HeaderCell = HeaderCell;
Table.Cell = Cell;

export default Table;
