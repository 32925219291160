import { getDayjs, getDayjsInGMT, getDuration } from '../utils/dayjs.utils';
import { useGetCurrentEvent } from './api/useGetCurrentEvent';

export const useGetCurrentEventTimeInfo = () => {
    const { event, userEvent, isLoading } = useGetCurrentEvent()
    const now = getDayjsInGMT();
    if (event && userEvent) {
        const isUserManuallyFinished = userEvent.isManuallyFinished;
        const eventStartDate = getDayjs(event!.startTime);
        const eventEndDate = getDayjs(event!.endTime);
        const userEventActivationDate = getDayjsInGMT(userEvent!.startTime);
        const eventDurationAfterActivation = getDuration(event!.duration);
        const userTimerToCompleteActivatedEvent = eventDurationAfterActivation - now.diff(userEventActivationDate);
        const isUserEventStillActive = userTimerToCompleteActivatedEvent > 0 && !isUserManuallyFinished;

        return {
            eventStartDate: eventStartDate,
            eventEndDate: eventEndDate,
            userEventActivationDate: userEventActivationDate,
            now: now,
            eventDurationAfterActivation: eventDurationAfterActivation,
            userTimerToCompleteActivatedEvent: userTimerToCompleteActivatedEvent,
            isUserEventStillActive: isUserEventStillActive,
            isLoadingTimeInfo: isLoading
        }
    }

    return {
        eventStartDate: null,
        eventEndDate: null,
        userEventActivationDate: null,
        now: now,
        eventDurationAfterActivation: null,
        userTimerToCompleteActivatedEvent: null,
        isUserEventStillActive: true,
        isLoadingTimeInfo: isLoading
    }

}