import dayjs from 'dayjs';
import { getDayjs } from './dayjs.utils';

const timeInitialValue = '00:00';
const now = getDayjs();

const fromDateInitialValue = now.format('DD.MM.YYYY');
const toDateInitialValue = now.add(10, 'day').format('DD.MM.YYYY');

const startTimeInitialValue = `${fromDateInitialValue}T${timeInitialValue}`;
const endTimeInitialValue = `${toDateInitialValue}T${timeInitialValue}`;

const startTimeInitialValueISO = dayjs(startTimeInitialValue, 'DD.MM.YYYYTHH:mm').format('YYYY-MM-DDTHH:mm:ss[Z]');
const endTimeInitialValueISO = dayjs(endTimeInitialValue, 'DD.MM.YYYYTHH:mm').format('YYYY-MM-DDTHH:mm:ss[Z]');

export const daysFromToInitialValue = {
    now: now,
    fromDateInitialValue,
    toDateInitialValue,
    timeInitialValue,
    startTimeInitialValue,
    endTimeInitialValue,
    startTimeInitialValueISO,
    endTimeInitialValueISO,
};
