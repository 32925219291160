import { IPaginationProps } from "../../types/base.interface";
import { ICreateEvent, IEvent, IUserEvent } from "../../types/event.interface";
import { IUserAttempts } from "../../types/problem.interface";
import { IUsers } from "../../types/user.interface";
import { api } from "../api";

export interface IStartEvent {
    eventId: string;
    language: string;
};

export interface IGetUserAttempts extends IPaginationProps {
    eventId: string;
    reviewed: boolean;
}

export interface IGetUsersWhoNotStarted extends IPaginationProps {
    id: string;
}

export interface IFinishUserEvent {
    eventId: string;
}

export interface IGrantEventAccessForGroup extends IFinishUserEvent {
    groupId: string;
}

export const eventApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getEvents: builder.query<IEvent[], void>({
            query: () => `/users/events`,
            providesTags: (result, error, arg) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Events' as const, id })), 'Events']
                    : ['Events'],
        }),
        getUserEvents: builder.query<IUserEvent[], void>({
            query: () => `/users/user-events`,
            providesTags: (result, error, arg) =>
                result
                    ? [...result.map(({ eventId }) => ({ type: 'UserEvents' as const, eventId })), 'UserEvents']
                    : ['UserEvents'],
        }),
        getEvent: builder.query<IEvent[], string>({
            query: (id) => `/events/${id}`,
            providesTags: (result, error, arg) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'UserEvents' as const, id })), 'UserEvents']
                    : ['UserEvents'],
        }),
        finishUserEvent: builder.mutation<void, IFinishUserEvent>({
            query: ({eventId}) => ({
                url:`/user-events/finish/${eventId}`,
                method: 'PUT',
            }),
            invalidatesTags: ['UserEvents'],
        }),
        createEvent: builder.mutation<{ id: string }, ICreateEvent>({
            query: (body) => ({
                url: `/events`,
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            invalidatesTags: ['UserEvents', 'Events'],
        }),
        grantEventAccessForGroup: builder.mutation<void, IGrantEventAccessForGroup>({
            query: ({eventId, groupId}) => ({
                url: `/events/${eventId}/group/${groupId}`,
                method: 'PUT',
            }),
            invalidatesTags: ['UserEvents', 'Events'],
        }),
        startEvent: builder.mutation<void, IStartEvent>({
            query: ({ eventId, language }) => ({
                url: `/events/${eventId}/start/${language}`,
                method: 'PUT',
            }),
            invalidatesTags: ['UserEvents'],
        }),
        getUserAttempts: builder.query<IUserAttempts, IGetUserAttempts>({
            query: ({ eventId, reviewed, pageNumber, pageSize }) => `/user-events/${eventId}/user-attempts/${reviewed}/${pageNumber}/${pageSize}`,
            providesTags: ['UsersAttempts'],
        }),
        getUsersWhoNotStarted: builder.query<IUsers, IGetUsersWhoNotStarted>({
            query: ({ id, pageNumber, pageSize }) => `/events/${id}/users-who-not-started-event/${pageNumber}/${pageSize}`,
            providesTags: ['UsersWhoNotStart'],
        }),
    }),
})