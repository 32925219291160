import {BlueButton} from "../../button/BlueButton";
import styled from "styled-components";

export interface PaginationButtonProps {
    active: boolean;
}

export const PaginationButton = styled(BlueButton)<PaginationButtonProps>`
    width: 30px;
    height: 30px;
    padding: 4px;
    background-color: ${(props) => (props.active ? '#0962F2' : 'transparent')};
    color: ${(props) => (props.active ? '#FFFFFF' : '#787676')};

    &:hover {
        color: #FFFFFF;
    }
`