import { toast } from "react-hot-toast";

export const getContentType = () => ({
    'Content-Type': 'application/json'
})

export const errorCatch = (error: any): string => error.response && error.response.data
    ? typeof error.response.data.error === 'object'
        ? error.response.data.error[0]
        : error.response.data.error
    : error.message

export const toastError = (error: any) => {
    const message = errorCatch(error);
    toast.error(message)
    throw message;
}