import { FC } from "react";
import { ROUTES } from "../../../routes/routes.config";
import { AuthLink } from "../ui/AuthLink";
import { AuthTitle } from "../ui/AuthTitle";
import { RegisterForm } from "./ui/form/RegisterForm";

export const RegisterPage: FC = ({ }) => {
    return (
        <>
            <AuthTitle>Регистрация</AuthTitle>
            <RegisterForm />
            <AuthLink to={ROUTES.auth.login.fullPath}>Войти</AuthLink>
        </>
    );
};
