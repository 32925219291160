import { Dayjs } from "dayjs";
import { IEvent, IUserEvent } from "../core/types/event.interface";
import { getDueDate, getDurationInString } from "./dayjs.utils";

export const getGroupMaxPoints = (events: IEvent[] | undefined, groupId: string) => {
    let sum = 0;
    events
        ?.filter(event => event.groupId === groupId)
        .map(event => event.problems
            .forEach(problem => sum += problem.points));
    return sum;
};

export const getGroupUserPoints = (
    userEvents: IUserEvent[] | undefined,
    events: IEvent[] | undefined,
    groupId: string,
) => {
    let sum = 0;
    userEvents
        ?.filter(userEvent => events
            ?.filter(event => event.groupId === groupId)
            .map(event => event.id)
            .includes(userEvent.eventId))
        .map(userEvent => userEvent.problemAttempts
            .forEach(problemAttempt => sum += problemAttempt.points))
    return sum
};

export const getInfo = (date?: { start: Dayjs, end: Dayjs }, problemsCount?: number, duration?: string) => {
    const info = [];

    if (date) {
        const calendarImg = require('../../src/assets/icons/main/calendar.svg').default;
        info.push({
            imgSrc: calendarImg,
            condition: getDueDate(date.start, date.end)
        });
    }
    if (problemsCount) {
        const problemImg = require('../../src/assets/icons/main/note.svg').default;
        info.push({
            imgSrc: problemImg,
            condition: `${problemsCount}`
        });
    }
    if (duration) {
        const timeLimitImg = require('../../src/assets/icons/main/time.svg').default;
        info.push({
            imgSrc: timeLimitImg,
            condition: getDurationInString(duration)
        });
    }

    return info;
}

export const getMaxPoints = (event: IEvent) => {
    let sum = 0;
    event.problems
        .forEach(problem => sum += problem.points)
    return sum
}

export const getUserPoints = (userEvent?: IUserEvent) => {
    let sum = 0;
    userEvent?.problemAttempts
        .forEach(problemAttempt => sum += problemAttempt.points)
    return sum
}