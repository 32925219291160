import { FC, PropsWithChildren } from "react";
import Skeleton from "react-loading-skeleton";
import { BodySmallRegularText } from "../../../styles/Text";

interface AuthErrorMessageProps {
    style?: object;
}

export const AuthErrorMessage: FC<PropsWithChildren<AuthErrorMessageProps>> = ({
    children,
    style,
}) => {
    return (
        <BodySmallRegularText style={{ color: "#F3163E", ...style }}>
            {children ? children : <Skeleton />}
        </BodySmallRegularText>
    );
};
