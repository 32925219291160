import { useState } from 'react';
import styled from 'styled-components';
import { BlueButton } from '../../../../common/main/button/BlueButton';
import { WhiteButton } from '../../../../common/main/button/WhiteButton';
import { BodyBigRegularText } from '../../../../../styles/Text';
import { IProblem } from '../../../../../core/types/problem.interface';
import { TaskBankPopup } from '../taskBank/TaskBankPopup';
import { TaskCard } from '../taskBank/taskCard';

const ChooseTaskContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
`;

const EmptyTaskList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
`;

const SelectedTasksList = styled.div`
    display: flex;
    flex-direction: column;
`;

interface ChooseTaskProps {
    selectTasks: (tasks: IProblem[]) => void;
    selectedTasks: IProblem[];
}

export const ChooseTask = ({ selectTasks, selectedTasks }: ChooseTaskProps) => {
    const [isShowPopup, setIsShowPopup] = useState(false);

    const openPopup = () => setIsShowPopup(true);
    const closePopup = () => setIsShowPopup(false);

    return (
        <ChooseTaskContent>
            {!selectedTasks.length && (
                <EmptyTaskList>
                    <BodyBigRegularText>Вы еще не выбрали ни одной задачи!</BodyBigRegularText>
                    <BlueButton
                        style={{ width: '200px', height: '40px' }}
                        onClick={openPopup}
                    >
                        {'Выбрать задачи'}
                    </BlueButton>
                </EmptyTaskList>
            )}
            {selectedTasks.length > 0 && (
                <>
                    <SelectedTasksList>
                        {selectedTasks?.map(problem => {
                            return <TaskCard key={`${problem.hash}-${Math.random()}`} {...problem}></TaskCard>;
                        })}
                    </SelectedTasksList>
                    <WhiteButton
                        style={{ width: '200px', height: '40px' }}
                        onClick={openPopup}
                    >
                        Редактировать
                    </WhiteButton>
                </>
            )}
            {isShowPopup && <TaskBankPopup onClose={closePopup} onSave={selectTasks} taskListToEdit={selectedTasks} />}
        </ChooseTaskContent>
    );
};
