import { FC } from "react";
import styled from "styled-components";
import { CourseCard, CoursesCardProps } from "./card/CourseCard";

const CoursesGridStyled = styled.div<Pick<CoursesGridProps, 'repeat'>>`
    width: 100%;
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(${({ repeat }) => repeat}, 1fr);
`

interface CoursesGridProps {
    courses: CoursesCardProps[]
    repeat: number
}

export const CoursesGrid: FC<CoursesGridProps> = ({ courses, repeat }) => {
    return (
        <CoursesGridStyled repeat={repeat}>
            {courses.map((course, index) => {
                return (
                    <CourseCard
                        key={index}
                        {...course}
                    />
                )
            })}
        </CoursesGridStyled>
    )
}
