import React, {FC} from "react";
import {EditTaskText, StyledNavLinkWithBackground} from "../../../Styles";
import {ROUTES} from "../../../../../../../routes/routes.config";
import {EditTaskIcon} from "../icons/EditTaskIcon";

export const EditTaskLink: FC = () => {
    return (
        <StyledNavLinkWithBackground to={ROUTES.teacher.editTask.fullPath}>
            <EditTaskIcon/>
            <EditTaskText>Редактировать</EditTaskText>
        </StyledNavLinkWithBackground>
    )
}